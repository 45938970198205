import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Marketplace from  '../views/market/Marketplace.vue'

import MarketArtifacts from  '../views/market/MarketArtifacts.vue'
import MarketStones from  '../views/market/MarketStones.vue'
import MarketTickets from  '../views/market/MarketTickets.vue'
import MarketFrames from  '../views/market/MarketFrames.vue'
import MarketItems from  '../views/market/MarketItems.vue'
import FighterView from  '../views/FighterView.vue'
import MarketFightersView from  '../views/market/MarketFightersView.vue'
import InventoryView from  '../views/InventoryView.vue'
import PortalView from  '../views/PortalView.vue'
import GameView from  '../views/GameView.vue'
import UnderConstructionView from  '../views/UnderConstructionView.vue'
import AccountSetting from  '../views/AccountSettingView.vue'
import ItemView from  '../views/ItemView.vue'
import Email from  '../views/Email.vue'
import RewardView from  '../views/RewardView.vue'
import DojoView from  '../views/DojoView.vue'
import DojoSimulationView from  '../views/DojoSimulationView.vue'
import GrandDojoView from  '../views/GrandDojoView.vue'
import OffersView from  '../views/OffersView.vue'
import NFTRecentListView from  '../views/NFTRecentListView.vue'
//import FreeMintView from '@/views/FreeMintView.vue'
import WorkshopView from '@/views/WorkshopView.vue'
import PolygonBridgeView from  '../views/PolygonBridgeView.vue'
import ScholarshipView from  '../views/ScholarshipView.vue'
import SignUpView from  '../views/SignUpView.vue'
import ReferralProgramView from  '../views/ReferralProgramView.vue'
//import Game from '../views/Game.vue'
//import Leaderboard from '../views/Leaderboard.vue'
//import Staking from '../views/Staking.vue'
//import Community from '../views/Community.vue'

const router = createRouter({
  history: createWebHistory(process.env.VITE_BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Marketplace
    },
    {
      path: '/marketplace',
      name: 'marketplace',
      component: Marketplace,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Marketplace', link: '/marketplace' }
      ], },
    },
    {
      path: '/marketplace/fighters',
      name: 'marketplace_fighters',
      component: MarketFightersView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Marketplace', link: '/marketplace' },
        { title: 'Fighters', link: '/marketplace/fighters' },
      ], },
    },
    {
      path: '/marketplace/frames',
      name: 'marketplace_frames',
      component: MarketFrames,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Marketplace', link: '/marketplace' },
        { title: 'Frames', link: '/marketplace/frames' },
      ], },
    },
    {
      path: '/marketplace/items',
      name: 'marketplace_items',
      component: MarketItems,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Marketplace', link: '/marketplace' },
        { title: 'Items', link: '/marketplace/items' },
      ], },
    },
    {
      path: '/marketplace/tickets',
      name: 'marketplace_tickets',
      component: MarketTickets,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Marketplace', link: '/marketplace' },
        { title: 'Tickets', link: '/marketplace/tickets' },
      ], },
    },
    {
      path: '/marketplace/stones',
      name: 'marketplace_stones',
      component: MarketStones,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Marketplace', link: '/marketplace' },
        { title: 'Stones', link: '/marketplace/stones' },
      ], },
    },
    {
      path: '/marketplace/artifacts',
      name: 'marketplace_artifacts',
      component: MarketArtifacts,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Marketplace', link: '/marketplace' },
        { title: 'Artifacts', link: '/marketplace/artifacts' },
      ], },
    },
    {
      path: '/game',
      name: 'game',
      component: GameView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Game', link: '/game' },
      ], },
    },
    {
      path: '/fighter',
      name: 'fighter',
      component: FighterView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Fighters', link: '/marketplace/fighters' },
        { title: 'Fighter', link: '/fighter' },
      ], },
    },
    {
      path: '/inventory/fighter',
      name: 'inventory_fighter',
      component: FighterView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Inventory', link: '/inventory' },
        { title: 'Fighter', link: '/fighter' },
      ], },
    },
    {
      path: '/inventory',
      name: 'inventory',
      component: InventoryView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'User Dashboard', link: '/inventory' },
        { title: 'Inventory', link: '/inventory' },
      ], },
    },
    {
      path: '/portal',
      name: 'portal',
      component: PortalView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'User Dashboard', link: '/inventory' },
        { title: 'Portal', link: '/portal' },
      ], },
    },
    {
      path: '/leaderboard',
      name: 'leaderboard',
      component: UnderConstructionView
    },
    {
      path: '/staking',
      name: 'staking',
      component: UnderConstructionView
    },
    {
      path: '/email',
      name: 'email',
      component: Email
    },
    {
      path: '/community',
      name: 'community',
      component: UnderConstructionView
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/account_setting',
      name: 'account_setting',
      component: AccountSetting,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'User Dashboard', link: '/inventory' },
        { title: 'Account Setting', link: '/account_setting' },
      ], },
    },
    {
      path: '/item',
      name: 'item',
      component: ItemView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Marketplace', link: '/marketplace' },
        { title: 'Item', link: '/item' },
      ], },
    },
    {
      path: '/inventory/item',
      name: 'inventory_item',
      component: ItemView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Inventory', link: '/inventory' },
        { title: 'Item', link: '/inventory/item' },
      ], },
    },
    {
      path: '/reward',
      name: 'reward',
      component: RewardView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'User Dashboard', link: '/inventory' },
        { title: 'Reward', link: '/reward' },
      ], },
    },
    {
      path: '/dojo',
      name: 'dojo',
      component: DojoView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'User Dashboard', link: '/inventory' },
        { title: 'Dojo', link: '/dojo' },
      ], },
    },
    {
      path: '/dojo-simulation',
      name: 'dojo-simulation',
      component: DojoSimulationView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'User Dashboard', link: '/inventory' },
        { title: 'Dojo', link: '/dojo-simulation' },
      ], },
    },
    {
      path: '/grand_dojo',
      name: 'grand_dojo',
      component: GrandDojoView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'User Dashboard', link: '/inventory' },
        { title: 'Grand Dojo', link: '/grand_dojo' },
      ], },
    },
    {
      path: '/offers',
      name: 'offers',
      component: OffersView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        // { title: 'User Dashboard', link: '/inventory' },
        { title: 'Offers', link: '/offers' },
      ], },
    }/*,
    {
      path: '/free_mint',
      name: 'free_mint',
      component: FreeMintView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Free Mint', link: '/free_mint' },
      ], },
    }*/,
    {
      path: '/fighter-workshop',
      name: 'fighter-workshop',
      component: WorkshopView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Fighter Workshop', link: '/fighter-workshop' },
      ], },
    }
    ,
    {
      path: '/iframe/nft-recent-list',
      name: 'nft-recent-list',
      component: NFTRecentListView,
      meta: { breadcrumbs: [
        // { title: 'Home', link: '/iframe/nft-recent-list' }
      ], },
    },
    {
      path: '/polygon-bridge',
      name: 'polygon-bridge',
      component: PolygonBridgeView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Polygon Bridge', link: '/polygon-bridge' },
      ], },
    },
    {
      path: '/scholarship-manager',
      name: 'scholarship-manager',
      component: ScholarshipView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Scholarship Manager', link: '/scholarship-manager' },
      ], },
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUpView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Sign Up', link: '/signup' },
      ], },
    },
    {
      path: '/referral-program',
      name: 'referral-program',
      component: ReferralProgramView,
      meta: { breadcrumbs: [
        { title: 'Home', link: '/marketplace' },
        { title: 'Referral Program', link: '/referral-program' },
      ], },
    },
  ]
})
export default router
/*
import { createRouter, createWebHistory } from 'vue-router';
import AboutView from '@/views/AboutView.vue';

// Define routes
const routes = [
  {
    path: '/',
    name: 'Home',
    component: AboutView,
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
  },
];

// Create router instance
const router = createRouter({
  history: createWebHistory(process.env.VITE_BASE_URL),
  routes,
});

export default router;*/
