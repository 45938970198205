import { defineStore } from 'pinia';
import axios from 'axios';

export const useExchangeStore = defineStore({
  id: 'exchange',
  state: () => ({
    ethToMaticExchangeRate: 0,
    maticToUsdExchangeRate: 0,
    ethToUsdExchangeRate: 0,
    kroToUsdExchangeRate: 0,
    exchangeRatesFetched: false,
  }),
  actions: {
    async fetchExchangeRates() {
      if (this.exchangeRatesFetched) {
        return;
      }
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum,kroma,matic-network&vs_currencies=usd'
        );
        const ethToUsd = response.data.ethereum.usd;
        const maticToUsd = response.data['matic-network'].usd;
        const kroToUsd = response.data['kroma'].usd;

        const ethToMaticExchangeRate = ethToUsd / maticToUsd;

        this.ethToMaticExchangeRate = ethToMaticExchangeRate;
        this.maticToUsdExchangeRate = maticToUsd;
        this.ethToUsdExchangeRate = ethToUsd;
        this.kroToUsdExchangeRate = kroToUsd;
        this.exchangeRatesFetched = true;

        console.log('fetchExchangeRates Response:', response);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    },
  },
  getters: {
    getEthToMaticExchangeRate: (state) => state.ethToMaticExchangeRate,
    getMaticToUsdExchangeRate: (state) => state.maticToUsdExchangeRate,
    getEthToUsdExchangeRate: (state) => state.ethToUsdExchangeRate,
    getKroToUsdExchangeRate: (state) => state.kroToUsdExchangeRate,
  },
  
});
