<template>

  <div class="top-bar text-white d-flex justify-content-between ">
    <BreadcrumbView style="margin-top: 14px; font-size: 14px;" />

    <div class="connect-button">
      <button
        v-if="!connected"
        class="btn btn-primary"
        @click="connectWallet"
      >
        Connect
      </button>
      <div
        v-else
        class="dropdown"
      >
      <div v-if="isUsePolygon">
          <button v-if="storeNetwork.state.isPolygonConnected"
            id="dropdownMenuButton"
            class="btn btn-warning"
            type="button"
            disabled
            style="opacity: unset; background-color: #171527; border-color: #ff65ed; color: #ffffff;"
          >
            <img
              src="../assets/class-users-default.png"
              style="padding: 0px; width: auto; height: 36px; margin-right: 6px;"
              alt="Sample Image"
            >
            {{ shortenedWalletAddress + " " }}
          </button>
          <button v-else
              class="btn btn-primary"
              @click="switchToPolygonNetwork"
            >
              <!-- <img
                :src="require('@/assets/icons/icon-kroma.png')"
                alt="NFT Icon"
                class=""
                style="width:24px;"
              >
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="30px" fill="#e8eaed">
                <path d="M280-160 80-360l200-200 56 57-103 103h287v80H233l103 103-56 57Zm400-240-56-57 103-103H440v-80h287L624-743l56-57 200 200-200 200Z"/>
              </svg> -->
              Switch to Polygon
              <img
                :src="require('@/assets/icons/icon-matic.png')"
                alt="NFT Icon"
                class=""
                style="width:26px; padding: 5px 0;"
              >
          </button>
        </div>
        <div v-else>
          <router-link
            to="/inventory"
            class="inventory-link"
            style="margin-right: 20px;"
          >  
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 640 512"><path fill="#a0a6b3" d="M184 48l144 0c4.4 0 8 3.6 8 8l0 40L176 96l0-40c0-4.4 3.6-8 8-8zm-56 8l0 40L64 96C28.7 96 0 124.7 0 160l0 96 192 0 128 0 192 0 0-96c0-35.3-28.7-64-64-64l-64 0 0-40c0-30.9-25.1-56-56-56L184 0c-30.9 0-56 25.1-56 56zM512 288l-192 0 0 32c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-32L0 288 0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-128z"/></svg>
          </router-link>
          <button
            id="dropdownMenuButton"
            class="btn btn-warning dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="../assets/class-users-default.png"
              style="padding: 0px; width: auto; height: 36px; margin-right: 6px;"
              alt="Sample Image"
            >
            {{ shortenedWalletAddress + " " }}
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
          >
            <li>
              <router-link
                to="/inventory"
                class="router-link"
              >
                <a class="dropdown-item">Dashboard</a>
              </router-link>
            </li>
            <li>
              <router-link
                to="/inventory"
                class="router-link"
              >
                <a class="dropdown-item">Inventory</a>
              </router-link>
            </li>
            <li>
              <router-link
                to="/offers"
                class="router-link"
              >
                <a class="dropdown-item">My Offers</a>
              </router-link>
            </li>
          
          <!--  <li>
              <router-link
                to="/dojo-simulation"
                class="router-link"
              >
                <a class="dropdown-item">Dojo (Simulator)</a>
              </router-link>
            </li>
            <li>
              <router-link
                to="/grand_dojo"
                class="router-link"
              >
                <a class="dropdown-item">Grand Dojo (Simulator)</a>
              </router-link>
            </li>-->
            <li>
              <router-link
                to="/reward"
                class="router-link"
              >
                <a class="dropdown-item">My Rewards</a>
              </router-link>
            </li> 
            <li>
              <router-link
                to="/portal"
                class="router-link"
              >
                <a class="dropdown-item">Portal</a>
              </router-link>
            </li>
            <li>
              <router-link
                to="/dojo"
                class="router-link"
              >
                <a class="dropdown-item">Coaching Lab</a>
              </router-link>
            </li>
            <!-- <li>
              <router-link
                to="/scholarship-view"
                class="router-link"
                @click.prevent="null"
              >
                <a class="dropdown-item">Scholarship Manager</a>
              </router-link>
            </li> -->
            <li>
              <router-link
                to="/account_setting"
                class="router-link"
              >
                <a class="dropdown-item">Account Settings</a>
              </router-link>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="disconnectWallet"
              >Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>

</template>

<script setup>
import Web3 from 'web3';
import { ref, onMounted, computed } from 'vue';
import BreadcrumbView from '@/components/BreadcrumbView.vue';
import Cookies from 'js-cookie';
import { useStore } from '@/stores/index.ts';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { useNetwork } from '@/stores/network.ts';
import ConfirmPopup from '@/components/popup/ConfirmPopup.vue'
import { eventBus } from '@/utils/walletUtils.js';

const isModalVisible = ref(false);
const showConfirmPopup = () => {
  console.log('showConfirmPopup');
  isModalVisible.value = true;
  $(document).ready(function() {
      $('#confirmPopup').modal('show');
  });
}

const onConfirm = () => {
  console.log('User confirmed', isModalVisible);
  isModalVisible.value = false;
  addKromaSepoliaNetwork();
}

const connected = ref(false);
const walletAddress = ref('');
const store = useStore();

const isConnected = Cookies.get('connected') === 'true';
console.log("isConnected" + isConnected);

const route = useRoute();
const isUsePolygon = computed(() => {
  return route.path === '/polygon-bridge';
});

const currentChainId = ref(null); // To hold the current chain ID
const storeNetwork = useNetwork();
const fetchChainId = async () => {
  try {
    currentChainId.value = await window.ethereum.request({ method: 'eth_chainId' });
    
  } catch (error) {
    console.error(error);
  }
  storeNetwork.setPolygonConnection(currentChainId.value === '0x89');
};

const apiKey = process.env.VITE_JAB_CONTRACT_ADDRESS;
console.log(apiKey);

const jabContractAbi = [
    {
        constant: true,
        inputs: [{ name: '_owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: 'balance', type: 'uint256' }],
        type: 'function',
    },
];

onMounted(async () => {
  // Check wallet connection when the component is mounted
  if (isConnected) {
    await checkWalletConnection(false);
  }
  
});

const connectWallet = async () => {
  await checkWalletConnection();
};

eventBus.on('connectWallet', connectWallet);

const checkWalletConnection = async (isShowError = true) => {
  try {
    if (typeof window.ethereum !== 'undefined') {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const networkId = await window.ethereum.request({ method: 'net_version' });
      console.log("networkId" ,networkId);
      console.log("networkId" ,process.env.VITE_NETWORK_ID);

      console.log("isUsePolygon" ,isUsePolygon.value);

      if (networkId === process.env.VITE_NETWORK_ID || networkId === '80001' || (networkId === '137' && isUsePolygon.value)) {
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        connected.value = true;
        walletAddress.value = accounts[0];
        store.setWalletAddress(walletAddress.value);
        Cookies.set('connected', connected.value);

        if(networkId === '137'){
          fetchChainId();
          return;
        }
        const jabContractAddress = process.env.VITE_JAB_CONTRACT_ADDRESS;
        console.log("jabContractAddress" +jabContractAddress);
        const jabTokenContract = new web3.eth.Contract(jabContractAbi, jabContractAddress);
        const jabBalanceWei = await jabTokenContract.methods.balanceOf(accounts[0]).call();
        const jabBalanceWith2DecimalPlaces = parseFloat(web3.utils.fromWei(jabBalanceWei, 'ether')).toFixed(0);

        store.setWalletBalance( {  jab: jabBalanceWith2DecimalPlaces , kf: 0});
        //console.log("Jab Balance" + jabBalanceWith2DecimalPlaces);
      }else {
        console.error('Please switch to the Kroma network in MetaMask');
        alert('Please switch to the Kroma network in MetaMask');
        //showConfirmPopup();
        // let result = confirm("Please switch to Kroma network.");
        // if (result) {
        //     console.log("User confirmed.");
        //     addKromaSepoliaNetwork();
        // } else {
        //     console.log("User canceled.");
        // }
        return 0;
      }
    } else {
      Cookies.set('connected', false);
      if (isShowError) alert('Please install MetaMask to use this feature.');
    }
  } catch (error) {
    console.error(error);
    Cookies.set('connected', false);
    if (isShowError) alert('Failed to connect to MetaMask. Please try again.');
  }

  fetchChainId();
};

const disconnectWallet = async () => {
  try {
    if (window.ethereum && window.ethereum.isConnected()) {
      await window.ethereum.request({ method: 'eth_requestAccounts', params: [] });
    }
    connected.value = false;
    walletAddress.value = '';
    Cookies.set('connected', connected.value);
    store.setWalletAddress(walletAddress.value);
    console.error('Wallet has been disconnected');
  } catch (error) {
    console.error(error);
    alert('Failed to disconnect from MetaMask. Please try again.');
  }

  fetchChainId();
};

const shortenedWalletAddress = computed(() => {
  if (walletAddress.value) {
    return `${walletAddress.value.slice(0, 6)}...${walletAddress.value.slice(-3)}`;
  }
  return '';
});

const switchToPolygonNetwork = async () => {  
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0x89', // Hexadecimal for 137
        chainName: 'Polygon Mainnet',
        rpcUrls: ['https://polygon-rpc.com/'], // Valid RPC URL
        nativeCurrency: {
          name: 'Polygon', // The native currency for Polygon is MATIC
          symbol: 'POL',
          decimals: 18
        },
        blockExplorerUrls: ['https://polygonscan.com/']
      }]
    });
    useToast().open({
      message: 'Polygon Network connected successfully.',
      position: 'top-right',
      type: 'success',
    });
  } catch (error) {
    console.error('Error adding network:', error);
    useToast().open({
      message: 'Switch failed on the Polygon Network.',
      position: 'top-right',
      type: 'error',
    });
  }
  fetchChainId();
};

const addKromaSepoliaNetwork = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0xFF',
        chainName: 'Kroma Testnet',
        rpcUrls: ['https://api.kroma.network/'],
        nativeCurrency: {
          name: 'Kroma ETH',
          symbol: 'ETH',
          decimals: 18
        },
        blockExplorerUrls: ['https://kromascan.com/']
      }]
    });
    useToast().open({
      message: 'Kroma Network added successfully.',
      position: 'top-right',
      type: 'success',
    });
    connectWallet();
  } catch (error) {
    console.error('Error adding network:', error);
    useToast().open({
      message: 'Error adding Kroma Network.',
      position: 'top-right',
      type: 'error',
    });
  }
};

</script>



<style scoped>
.top-bar {
  position: fixed;
  background-color: var(--dark-background-color);
  height: max-content;
  /* padding-left: 36px !important; */
  padding: 8px;
  top: 0;
  width: 100%; 
  z-index: 2;
  /* margin-left: 246px; */
}


.top-bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; /* Set the height of your gradient line */
  background: linear-gradient(to right, #201e2e, #201e2e,#201e2e); /* Gradient colors */
}

.connect-button {
  padding-right: 60px; /* Add padding to the right side of the Connect button */
}

.dropdown-toggle {
    background-color: #171527; /* Set background color */
    border-color: #ff65ed; /* Set border color */
    color: #ffffff; /* Set text color */
}

.dropdown-toggle:hover {
    background-color: #fa65ff55; /* Set hover background color */
    border-color: #f565ff; /* Set hover border color */
    color: #ffffff; /* Set hover text color */
}

.router-link{
  text-decoration: none; 
}

.a {
  line-height: 2rem;
}

.svg {
    width: 20px;
}

.inventory-link:hover svg path {
  fill: #bc7ffe;
}

@media (max-width: 768px) {
  .top-bar {
    width: 100% !important; /* Set the width to 100% minus the width of the left menu */
    padding-left: 50px !important;
    margin-left: 0px !important;
  }

  .connect-button {
    padding-right: 20px; /* Add padding to the right side of the Connect button */
  }
}

</style>
