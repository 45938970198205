<template>
  <div
    id="fittingPopup"
    class="modal fade no-padding"
    tabindex="-1"
    aria-labelledby="fittingPopup"
    aria-hidden="true"
    @click.self="selectButton"
  >
    <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width: 700px;">
      <div class="modal-content" style="background-color: #151030; border: 1px solid #7A37E6; border-radius: 8px;">
        <div class="modal-header">
          <div style="background: linear-gradient(20deg, rgb(248, 4, 156) 20%, rgb(74, 31, 187) 80%); height: 0.5em; width: 100%; border-radius: inherit;"></div>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="selectButton"
            style="margin: 0.25em 0.5em; padding: 0;"
          />
        </div>
        <div class="modal-body" style="padding: 0;">
          <div class="d-flex align-items-center justify-content-center" style="margin-top: -10px;">
            <img class="electric-bg" :src="require(`@/assets/workshop/electric-bg.png`)" alt="electric bg" style="height: 90px; position: relative;" />
            <div class="d-flex align-items-center justify-content-center" style="position: absolute; z-index: 1; flex-direction: column;">
              <img class="electric-bg" :src="require(`@/assets/workshop/outfit-header.png`)" alt="electric bg" style="height: 40px;" />
              <div style="font-size: 18px; font-weight: 500;">
                Equip your Fighter's suit
              </div>
            </div>
          </div>
          <div class="d-flex" style="width: 100%; padding: 15px 25px; flex-direction: column; align-items: center;">
            <div class="d-flex selected-content" style="">
              <div class="d-flex half-selected-content" style="justify-content: space-between;">
                <div style="width: 100%; display: flex; justify-content: center; align-items: center; gap: 10px;">
                  <div class="content-box total-box" style="">
                    Total Qty : {{ quantity }}
                  </div>
                  <div class="content-box total-box" style="">
                    Total Score : {{ score }}
                  </div>
                </div>
                <div class="content-box d-flex align-items-center justify-content-center selected-suit-blog" style="flex-direction: column;">
                  <div class="content-box d-flex align-items-center justify-content-center cobweb-blog">
                    <img class="cobweb-img" :src="require(`@/assets/workshop/cobweb-bg.png`)" alt="cobweb bg" style="position: relative;" />
                    <div class="d-flex align-items-center justify-content-center pentagon-image" style="position: absolute;">
                      <svg class="polygon-image" height="auto" viewBox="0 0 200 200" style="/*transform: translate(17%, 17%);*/ overflow: visible;">
                        <polygon
                          :points="pentagonPoints"
                          fill="none"
                          stroke="#644FC0"
                          stroke-width="7"
                        />
                        <g v-for="(circle, index) in circles" :key="index">
                          <circle
                            :cx="circle.x"
                            :cy="circle.y"
                            r="35"
                            fill="#644FC0"
                          />
                          <image
                            :href="(selectedSuit[index].item) ? selectedSuit[index].item.image : require(`@/assets/workshop/suit/default/${selectedSuit[index].part}.png`)"
                            :x="(selectedSuit[index].item)?circle.x - 25:circle.x - 21"
                            :y="(selectedSuit[index].item)?circle.y - 25:circle.y - 21"
                            :width="(selectedSuit[index].item) ? 50:42"
                            :height="(selectedSuit[index].item) ? 50:42"
                            :opacity="(selectedSuit[index].item) ? 1.0:0.5"
                          />
                          <text
                            :x="circle.x"
                            :y="(index < 2 || index == 4)? circle.y - 45:circle.y + 50"
                            text-anchor="middle"
                            fill="white"
                            font-size="14"
                            font-weight="500"
                          >
                            {{ selectedSuit[index].part_name }}
                          </text>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div v-if="quantity == 5" class="benefit-text" style="">You are now eligible for $KF airdrop</div>
                </div>
              </div>
              <div class="content-box d-flex half-selected-content" style="padding: 10px;">
                <div class="d-flex" style="gap: 10px; flex-wrap: wrap; justify-content: center;">
                  <template v-for="part in getPartNameList()" :key="part">
                    <label
                      class="btn btn-secondary dropdown-filter-toggle recipe-button"
                      :class="{ active: isSelectedPart(part) }"
                      style="padding: 0.15rem 0.6rem;"
                    >
                      <input
                        type="radio"
                        class="btn-check"
                        v-model="selectedPart"
                        @change="toggleSelectedPart(part)"
                        style="display: none;"
                      />
                      <span class="" style="font-size: 14px;">{{ part }}({{ partAmount[part]?? 0 }})</span>
                    </label>
                  </template>
                </div>
                <div class="content-box select-suit-content" style="">
                  <div style="width: 100%; height: 100%;">
                    <div class="item-container">
                      <label v-for="item in filterTotalSuit"
                        :key="item.id"
                        class="item"
                        :class="{ active: isSelectedSuit(item.id) }"
                        style=""
                      >
                        <input
                          type="checkbox"
                          class="btn-check"
                          @change="toggleSelectedSuit(item)"
                          style=""
                        />
                        <img class="item-image" width="auto" :src="item.image" alt="Head icon" style="" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img class="" :src="require(`@/assets/workshop/bottom-bar.png`)" alt="bottom bar" style="width: 60%;" />
          </div>
        </div>  
        <div v-if="isLoading" class="vertical-opacity-overlay d-flex justify-content-center align-items-center" style="height: 100%; z-index: 2;">
          <div>
            <i class="fa fa-circle-o-notch fa-spin" style="font-size: 30px;"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { getSignature } from '@/utils/web3Utils';
import NFTContractABI from '@/contracts/NFTContractABI.json';
import {  sendMessage } from '@/utils/walletUtils';
import {useToast} from 'vue-toast-notification';
import { ref, onMounted, watch } from 'vue';

export default {
    components: {
    },
    props: {
        showModal: Boolean,
        userScore: {
          type: Number,
          required: true 
        },
        isSuitCrafted: Boolean
    },
    data() {
        return { 
          isLoading: false,
          score: 0,
          quantity: 0,
          // totalSuit: [{id: 1, itemId: 1, part: 'helmet', name: 'HELMET COMMON', image: 'https://asset.kryptofighters.io/nft-fighter-suit/1.png', point: 10, rarity: 'common'}],
          totalSuit: [],
          filterTotalSuit: [],
          selectedSuit: [{part:'helmet', part_name:'Head gear', item: null},
                          {part:'gloves', part_name:'Gloves', item: null},
                          {part:'socks', part_name:'Socks', item: null},
                          {part:'pants', part_name:'Pants', item: null},
                          {part:'body', part_name:'Body gear', item: null}],
          circles: [{ x: 100, y: 20},
                    { x: 180, y: 80},
                    { x: 150, y: 180},
                    { x: 50, y: 180},
                    { x: 20, y: 80},
                    ],
          partAmount: {},
          selectedPart: 'All',
          totalRarity: ['common', 'rare', 'epic', 'legend', 'street fighter set']
        };
    },
    watch: {
      userScore(newVal) {
          this.score = newVal;
      },
      isSuitCrafted(newValue){
        if(newValue == true){
          this.fetchData();
        }
      }
    },
    computed: {
      pentagonPoints() {
        return [
          100, 20,   // top
          180, 80,   // right
          150, 180,  // bottom right
          50, 180,   // bottom left
          20, 80,    // left
        ].join(' ');
      },
    },
    async mounted() {
      console.log(this.userScore);
      this.score = this.userScore;
      if(useStore().getWalletAddress!=''){
          this.isLoading = true;
          await this.fetchData();
          await this.fetchEquippedSuit();
          this.isLoading = false;
      }else{
        watch(() => useStore().getWalletAddress, async (newWalletAddress) => {
          if (newWalletAddress !== '') {
            this.isLoading = true;
            await this.fetchData();
            await this.fetchEquippedSuit();
            this.isLoading = false;
          }
        });
      }
    },
    methods: {
      getPartNameList() {
        var nameList = ['All'];

        this.selectedSuit.forEach((suit) => {
          nameList.push(suit.part_name);
        });

        console.log(nameList);

        return nameList;
      },
      async fetchData() {
        try {
            const accountAddress = useStore().getWalletAddress;
            
            const collection = "0xd333D2901627fadA9B71897E9De04B473093b8F4";
            const requestBody = {
                size: 150,
                filter: {
                    blockchains: ['KROMA'],
                    owners: [`ETHEREUM:${accountAddress}`],
                    collections: [`KROMA:${collection}`]
                }
            };
            const aApiUrl = `https://api.rarible.org/v0.1/ownerships/search`;
            const headers = {
                    'accept': 'application/json',
                    'x-api-key': process.env.VITE_RARIBLE_API_KEY
                };
            const response = await axios.post(aApiUrl, requestBody, {headers});
            console.log("suitsResponse ",response);

            const itemApiUrl = `https://api.rarible.org/v0.1/items/byIds`;
            const itemTokenList = response.data.ownerships.map((item) => {
              return item.itemId;
            })
            const itemRequestBody = {
                ids: itemTokenList
            };
            const itemResponse = await axios.post(itemApiUrl, itemRequestBody, {headers});
            console.log("itemResponse ",itemResponse);
            
            this.totalSuit = await Promise.all(itemResponse.data.items.map(async (nft) => {
                try {
                    // const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter-suit/${nft.tokenId}.json`);
                    const kfResponse = await axios.get('https://s3.ap-southeast-1.amazonaws.com/'+(nft.meta.originalMetaUri.split("https://")[1]));
                    console.log("response.data ",kfResponse);

                    if (typeof kfResponse.data !== 'string'){
                      var part = kfResponse.data.attributes.part?.toLowerCase()?? '';

                      if(part == ''){
                        if(kfResponse.data.name.toLowerCase().includes('HELMET')){
                          part = 'helmet';
                        }
                        else if(kfResponse.data.name.toLowerCase().includes('GLOVE')){
                          part = 'gloves';
                        }
                        else if(kfResponse.data.name.toLowerCase().includes('BODY')){
                          part = 'body';
                        }
                        else if(kfResponse.data.name.toLowerCase().includes('PANT')){
                          part = 'pants';
                        }
                        else {
                          part = 'socks';
                        }
                      }

                      return {
                          id: nft.tokenId, 
                          itemId: kfResponse.data.item_id,
                          part: part,
                          name: kfResponse.data.name,
                          image: kfResponse.data.image,
                          point: kfResponse.data.attributes.point,
                          rarity: kfResponse.data.attributes.rarity
                      };
                    }
                    return null;
                } catch (error) {
                    console.error(`Error fetching metadata for tokenId ${nft.tokenId}:`, error);
                    return null;
                }
            }));
            // console.log("totalSuit" , JSON.stringify(this.totalSuit) );
            this.totalSuit = this.totalSuit.filter(f=>f && f != null);
            // this.totalSuit = [...this.totalSuit, ...this.totalSuit, ...this.totalSuit, ...this.totalSuit];
            const partOrder = this.selectedSuit.map(suit => suit.part);
            this.totalSuit.sort((a, b) => {
              const partIndexA = partOrder.indexOf(a.part);
              const partIndexB = partOrder.indexOf(b.part);
              if (partIndexA !== partIndexB) {
                return partIndexA - partIndexB;
              }

              const rarityIndexA = this.totalRarity.indexOf(a.rarity);
              const rarityIndexB = this.totalRarity.indexOf(b.rarity);
              return rarityIndexB - rarityIndexA;
            });

            console.log("totalSuit" , JSON.stringify(this.totalSuit) );
            this.filterTotalSuit = this.totalSuit;
            this.partAmount['All'] = this.totalSuit.length;
            this.partAmount['Head gear'] = this.totalSuit.filter(f=>f.part == 'helmet').length;
            this.partAmount['Gloves'] = this.totalSuit.filter(f=>f.part == 'gloves').length;
            this.partAmount['Socks'] = this.totalSuit.filter(f=>f.part == 'socks').length;
            this.partAmount['Pants'] = this.totalSuit.filter(f=>f.part == 'pants').length;
            this.partAmount['Body gear'] = this.totalSuit.filter(f=>f.part == 'body').length;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
      },
      closeModal(){
        console.log('closeModal');
        // this.$refs.Close.click();
      },
      selectButton() {
          console.log('Closing modal'); // Add this line
          // this.$emit('selectButton', 1);
          this.$emit('updateScore', this.score);
          this.closeModal();
      },
      isSelectedSuit(id) {
        return this.selectedSuit.some(suit => (suit.item?.id?? 0) == id);
      },
      toggleSelectedSuit(item) {
        this.isLoading = true;
        var selectedPart = [];
        if(item.part == 'helmet'){
          selectedPart = this.selectedSuit.find(h => h.part === 'helmet');
        }
        else if(item.part == 'body'){
          selectedPart = this.selectedSuit.find(a => a.part === 'body');
        }
        else if(item.part == 'gloves'){
          selectedPart = this.selectedSuit.find(g => g.part === 'gloves');
        }
        else if(item.part == 'socks'){
          selectedPart = this.selectedSuit.find(s => s.part === 'socks');
        }
        else if(item.part == 'pants'){
          selectedPart = this.selectedSuit.find(p => p.part === 'pants');
        }

        if(selectedPart){
          const currentItemId = selectedPart.item?.id ?? "";
          if(currentItemId === item.id){
            this.unequipSuit(selectedPart.item);
            selectedPart.item = null;
          }
          else{
            selectedPart.item = item;
            this.equipSuit(item);
          }
        }

        // this.calculateScore();
        this.updateData();
        console.log("toggleSelectedSuit ", this.selectedSuit);
      },
      isSelectedPart(part) {
        return this.selectedPart == part;
      },
      toggleSelectedPart(part) {
        this.selectedPart = part;
        if(part == 'All'){
          this.filterTotalSuit = this.totalSuit;
        }
        else{
          const partId = this.selectedSuit.find(f=>f.part_name == part).part;
          console.log(partId);
          this.filterTotalSuit = this.totalSuit.filter(p=>p.part == partId);
          console.log("toggleSelectedSuit ", this.filterTotalSuit);
        }
      },
      calculateScore(){
        this.score = 0;
        this.quantity = 0;

        var firstRarity = '';
        var rarityCount = 0;
        console.log('firstRarity' +firstRarity);
        this.selectedSuit.forEach(suit => {
          if(suit.item){
            this.score += Number(suit.item.point);
            this.quantity++;

            console.log('this.selectedSuit.length' +this.selectedSuit.length);
            console.log('suit.item.rarity' +suit.item.rarity);
            if(firstRarity === ''){
              firstRarity = suit.item.rarity;
            }
            else{
              if(suit.item.rarity !== firstRarity){
                return;
              }
            }
            rarityCount++;
            console.log(rarityCount);
          }
        });

        if(rarityCount == 5){
          this.score = this.score*2;
        }

        this.$emit('updateScore', this.score);
      },
      async fetchEquippedSuit() {
        try {
          const wallet = useStore().getWalletAddress;
          const apiUrl = `https://reward-portal.kryptofighters.io/api/outfit/status?wallet=${wallet}`;

          const headers = {
              'x-api-key': 'Xd710uEYzr'
          };

          const response = await axios.get(apiUrl , { headers });
          console.log('fetchEquippedSuit', response);

          console.log(this.totalSuit);
          response.data.data.forEach((suit) => {
            const _item = this.totalSuit.find(f => f.id == suit.item_id && f.part == suit.part);

            if(_item){
              this.selectedSuit.find(a => a.part === suit.part).item = _item;
            }
          });
          this.calculateScore();
          console.log(this.selectedSuit);
        } catch (error) {
            this.error = 'Failed to fetch data. Please try again later.';
            console.error(error);
        }
      },
      updateData(){
        const audio = new Audio(require('@/assets/sound/SFX_Equip.wav'));
        audio.play(); 

        this.calculateScore();
      },
      async equipSuit(item) {
        try {
          const wallet = useStore().getWalletAddress;
          const apiUrl = `https://reward-portal.kryptofighters.io/api/outfit/equip`;

          const headers = {
              'x-api-key': 'Xd710uEYzr'
          };

          const requestBody = {
              wallet: useStore().getWalletAddress,
              part: item.part,
              item_id: item.id
          };

          const response = await axios.post(apiUrl, requestBody, { headers });
          console.log('equipSuit', response);
          
          /*useToast().open({
                    message: response.data.message,
                    position: 'top-right',
                    type: 'success',
                });*/
          sendMessage( useStore().getWalletAddress , response.data.message);
        } catch (error) {
            console.error('Failed to equipSuit.' , error);
            useToast().open({
                      message: 'Error: Failed equip suit. Please try again later.' ,
                      position: 'top-right',
                      type: 'error',
                  });
        }
        this.isLoading = false;
      },
      async unequipSuit(item) {
        try {
          const wallet = useStore().getWalletAddress;
          const apiUrl = `https://reward-portal.kryptofighters.io/api/outfit/unequip`;

          const headers = {
              'x-api-key': 'Xd710uEYzr'
          };

          const requestBody = {
              wallet: useStore().getWalletAddress,
              part: item.part,
              item_id: item.id
          };

          const response = await axios.post(apiUrl, requestBody, { headers });
          console.log('equipSuit', response);
          /*useToast().open({
                    message: response.data.message,
                    position: 'top-right',
                    type: 'success',
                });*/
          sendMessage( useStore().getWalletAddress , response.data.message);
        } catch (error) {
          console.error('Failed to equipSuit.' , error);
            useToast().open({
                      message: 'Error: Failed unequip suit. Please try again later.' ,
                      position: 'top-right',
                      type: 'error',
                  });
        }
        this.isLoading = false;
      },
    }
};
</script>

<style scoped lang="scss">
  body.modal-open {
    overflow: hidden;
  }

  .modal-dialog {
    overflow: hidden;
  }

  .no-padding {
    padding: 0 !important;
    overflow: unset;
  }

  .modal-header {
    border-bottom: none;
    padding: 0;
    flex-direction: column;
    align-items: end;
    position: relative;
    z-index: 2;
  }
  
  .dropdown-filter-toggle {
    background-color: #101327;
  }

  .modal-title {
    text-align: center;
    width: 100%;
    font-weight: 600;
  }

  .dropdown-filter-toggle.active {
    background-color: #ff65fc55;
    border-color: #ff65fa;
    color: white;
  }

  .detail-text {
    color: white;
    display: inline-flex;
    align-items: center;
    background-color: var(--light-background-color);
    border-radius: 8px;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    width: 60px;
    font-weight: 500;
  }

  .width-detail-text{
    width: 100px;
  }

  .material-icon {
    width: 100px;
  }

  .btn-decrease {
    background-color: black;
  }

  .btn-increase {
    background-color: black;
  }

  .btn-decrease:hover:not(.btn-disabled){
    background-color: darkred;
  }

  .btn-increase:hover:not(.btn-disabled) {
    background-color: darkgreen;
  }

  .btn-disabled{
    background-color: #e0e0e0; /* Grey */
    color: darkgray; /* Light grey */
    cursor: not-allowed;
    opacity: 0.6;
  }

  .amount-content {
    width: 40px; 
    height: 100%; 
    background-color: black;
    color: white;
  }

  .item-container {
    display: inline-grid;
    grid-template-columns: repeat(4, calc(25% - 5px));
    width: 100%;
    grid-gap: 5px;
    // height: inherit;
    overflow-y: scroll;
  }

  .item {
    width: auto; /* Fixed width for each item */
    height: auto; /* Fixed height for each item */
    box-sizing: border-box; /* Ensure padding and border are included in width */
    text-align: center;
    // padding: 5px 5px; 
    cursor: pointer; 
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .item.active {
    background-color: #ff65fc55;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
  }
  
  .item-image {
    width: auto;
    max-width: 50px;
  }

  .selected-suit {
    flex-direction: column; 
    height: 80px; 
    width: 80px;
  }

  .content-box {
    background-color: #241D4F; 
    border-radius: 8px;
  }
  
  .total-box {
    width: 40%; 
    padding: 5px 10px; 
    font-weight: 500;
  }

  .pentagon-container {
    position: relative;
    width: 200px;
    height: 200px;
    margin: auto;
  }
  .pentagon {
    position: relative;
    width: 100%;
    height: 100%;
    clip-path: polygon(
      50% 0%,
      100% 38%,
      82% 100%,
      18% 100%,
      0% 38%
    );
    background-color: transparent; /* Optional: set to your desired color */
    border: 2px solid #644FC0; /* Optional: border style */
  }

  .selected-content {
    width: 100%; 
    gap: 15px;
  }

  .half-selected-content {
    width: 50%; 
    flex-direction: column;
    gap: 10px;
  }

  .cobweb-img {
    width: 90%;
  }

  .select-suit-content {
    background-color: #151030 !important; 
    height: 260px; 
    padding: 10px;
    padding-right: 5px;
    min-height: 200px;
  }

  .benefit-text {
    color: #FFD600; 
    font-weight: 400; 
    font-size: 14px; 
    padding: 10px 0;
  }

  .select-suit-blog {
    padding: 15px 0; 
    flex-direction: column;
  }

  .polygon-image {
    width: 60%;
  }

  .cobweb-blog {
    margin: 0;
  }

  .pentagon-image {
    height: 50%; 
    width: 50%;
  }

@media (max-width: 768px) {
    .no-padding {
      padding: 0 !important;
      overflow: auto;
    }

    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }

    .material-icon {
      width: 70px;
    }

    // .item-container {
    //   grid-template-columns: 50% 50%;
    // }

    .selected-suit {
      width: 100px;
    }

    .selected-content {
      flex-direction: column;
    }

    .half-selected-content {
      width: 100%;
    }

    .cobweb-img {
      width: 50%;
    }

    .benefit-text {
      font-size: 14px; 
      padding: 10px 0;
    }

    .select-suit-blog {
      padding: 25px 0; 
    }

    .polygon-image {
      width: 80%;
    }

    .cobweb-blog {
      margin: 0;
    }

    .pentagon-image {
      height: 40%; 
      width: 40%;
    }

    .select-suit-content {
      height: 300px; 
    }

    .total-box {
      font-size: 14px;
      width: 50%;
    }
}
</style>
  
