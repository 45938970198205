<template>
    <div class="page-content content-wrap" style="/*padding: 50px 0px;*/" :style="{ backgroundImage: `url(${imageUrls['main-bg']})`, display: isLoading ? 'none' : 'block' }">
      <img class="icon-image-kf" :src="imageUrls['fw-icon']" @load="onImageLoad" @error="onImageError" alt="workshop icon" style="z-index: 1;" />
      <div style="background-color: #0A0832AA; position: absolute; z-index: 0; width: 100%; transform: translate(0, 280%); overflow: hidden; white-space: nowrap;">
            <span class="marquee-text" style="color: yellow; line-height: 2;">Gas fees are covered by the service. Start collecting fighter suits today to access the $KF airdrop (TGE in March 2025) </span>
        </div>

        <div style="background-color: #100d42AA; position: absolute; z-index: 0; width: 100%; height: 90px;  overflow: hidden; white-space: nowrap;">
            
        </div>
      <div class="main-content" style="z-index: 1;">
        <!-- <div class="d-flex align-items-left">
          <h1>Fighter Workshop</h1>
        </div> -->
        <div class="menu-content" style="">
            <div>
                <div class="justify-content-end d-flex fragment-content" style="">
                    <div class="d-flex xp-content" style="">
                        <div class="d-flex justify-ceonten-center content-font" style="font-size: 20px; gap: 5px;">
                            <span><i>XP </i></span>
                            <img 
                                class="" 
                                :src="require(`@/assets/workshop/icon-info.png`)"
                                style="height: 20px;">
                        </div>
                        <div class="d-flex align-items-center" style="margin-top: -15px;">
                            <img width="45px" height="auto" class="material-icon" :src="require(`@/assets/workshop/icon-exp.png`)" style="position: relative; z-index: 2; margin-right: -10px;" />
                            <div class="d-flex align-items-center content-font" style="position: relative; height: 70%; padding: 0 30px; margin: 3px 0; background-color: #1F0E63;">
                                <span style="height: 100%; padding-top: 6px;">{{ userXp }}</span>
                            </div>                            
                            <button 
                                class="workshop-menu-list" 
                                @click="redirectToPath('ZEALY')"
                                style="position: relative; height: auto !important;">
                                <img 
                                    class="" 
                                    :src="require(`@/assets/workshop/icon-increaseExp.png`)"
                                    style="height: 35px; margin-left: -10px;">
                            </button>
                        </div>
                    </div>
                    <div class="justify-content-end" style="display: flex;">
                        <div v-for="item in totalFragment" class="d-flex">
                            <div v-if="item.code !== 'ITM_FM_FST'" style="display: inline-flex; align-items: end; margin-left: 10px;">
                                <img width="40px" height="40px" class="material-icon" :src="`../images/item/${item.code}.png`" alt="material 1 icon" style="" />
                                <h4 class="content-font" style="margin: 0;">x {{ item.amount }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="menu-col" style="">
                    <div class="d-flex" style="flex-direction: column; justify-content: space-between;">
                        <div>
                            <div :style="{ backgroundImage: `url(${imageUrls['season']})`}" style="background-size: contain; background-position: center; padding: 20px; min-width: 190px;">
                                <div>
                                    <div style="font-family: 'Zing Rust Base'; font-size: 30px">Pre-season</div>
                                    <div class="d-flex align-items-center" style="margin-top: -10px; gap: 10px;">
                                        <!-- <img :src="require(`@/assets/workshop/icon-time.png`)" width="30px" alt="time icon" style="" /> -->
                                        <svg id="time-img-id" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="24px" fill="#14FF00"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"/></svg>
                                        <div class="menu-bottom-list-font" style="margin-top: 10px; font-weight: 500 !important; letter-spacing: normal;">
                                            <span v-if="timeLeft.timeDifference > 0" style="color:#14FF00;">{{ timeLeft.days }}d {{ timeLeft.hours }}h {{ timeLeft.minutes }}m {{ timeLeft.seconds }}s</span>
                                            <span v-else style="color: #c20202;">Ended</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :style="{ backgroundImage: `url(${imageUrls['score']})`}" style="background-size: contain; background-position: center; padding: 20px;">
                                <div class="d-flex justify-content-between menu-bottom-list-font" style="margin-top: 10px; font-weight: 500 !important; letter-spacing: normal;">
                                    <span>Score</span>
                                    <span style="color: #F4D906">{{ userScore }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex" style="gap: 30px;">
                            <button class="menu-bottom-list" @click="redirectToPath('GUIDE')" style="justify-content: end !important;">
                                <img class="menu-bottom-list-icon" :src="imageUrls['guide']" @load="onImageLoad" @error="onImageError" alt="guide icon" style="" />
                                <span class="menu-bottom-list-font"> Guide </span>
                            </button>
                            <button class="menu-bottom-list" :disabled="true"  style="justify-content: end !important;">
                                <img class="menu-bottom-list-icon" :src="imageUrls['about']" @load="onImageLoad" @error="onImageError" alt="about icon" style="" />
                                <span class="menu-bottom-list-font"> About </span>
                            </button>
                            <button class="menu-bottom-list" @click="redirectToPath('REWARD')" :disabled="false"  style="justify-content: end !important;">
                                <img class="menu-bottom-list-icon" :src="imageUrls['rewardpool']" @load="onImageLoad" @error="onImageError" alt="rewardpool icon" style="" />
                                <span class="menu-bottom-list-font"> Reward </span>
                            </button>
                        </div>
                    </div>
                    <div 
                        class="center-content d-flex" 
                        style="/*min-height:400px; width:30%; */ gap: 10px !important;">
                        <div class="d-flex justify-content-center" style="width: 100%; gap: 30px;">
                            <button 
                            class="d-flex justify-content-center align-items-center workshop-menu-list top-menu-list" 
                            @click="redirectToPath('ZEALY')"
                            :disabled="true"
                            style="">
                                <img 
                                    class="" 
                                    :src="require(`@/assets/workshop/icon-invite.png`)"
                                    style="height: 26px;">
                                <span>Invite Friend</span>
                            </button>
                            <button 
                            class="d-flex justify-content-center align-items-center workshop-menu-list top-menu-list" 
                            data-bs-toggle="modal" 
                            data-bs-target="#factoryPopup"
                            :disabled="true"
                            style="">
                                <img 
                                    class="" 
                                    :src="require(`@/assets/workshop/icon-get.png`)"
                                    style="height: 26px;">
                                <span>Buy Fragments</span>
                            </button>
                        </div>
                        <div 
                        class="center-content d-flex" 
                        style="/*min-height:400px; width:30%; */ margin: 0 !important;">
                            <div 
                                class="d-flex" 
                                style="/* width:auto; *//* height: auto; *//* display: flex; width: 100%; */ justify-content: center; width: 100%;">
                                <button 
                                    class="workshop-menu-list" 
                                    id="forgeBtn" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#craftPopup"
                                    style="">
                                    <img 
                                        class="workshop-menu-list-img" 
                                        :src="imageUrls['forge']"
                                        @load="onImageLoad"
                                        @error="onImageError"
                                        alt="forge icon" style="/*height: 140px;*/position: relative; z-index: 1;">
                                    <img 
                                        class="workshop-menu-list-img-aura" 
                                        :src="require(`@/assets/workshop/aura-yellow.png`)"
                                        alt="forge icon" style="/*height: 140px;*/">
                                </button>
                            </div>
                            <div 
                                class="d-flex row-button" 
                                style="">
                                <div 
                                    class="button-element"
                                    style="">
                                    <button 
                                        class="workshop-menu-list pink-button button-element-left" 
                                        id="outfitBtn" 
                                        data-bs-toggle="modal" 
                                        data-bs-target="#fittingPopup" 
                                        :disabled="false"
                                        style="clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);">
                                        <img 
                                            class="workshop-menu-list-img workshop-menu-list-img-2" 
                                            :src="imageUrls['outfit']"
                                            @load="onImageLoad" 
                                            @error="onImageError"
                                            alt="outfit icon" 
                                            style="">
                                    </button>
                                    <img 
                                            class="workshop-menu-list-img-aura-2" 
                                            :src="require(`@/assets/workshop/aura-left.png`)"
                                            alt="aura icon" style="top: -45%; left: -25%;">
                                </div>
                                <div 
                                    class="button-element"
                                    style="">
                                    <button 
                                        class="workshop-menu-list pink-button button-element-right" 
                                        id="inventoryBtn" 
                                        @click="redirectToPath('INVENTORY')"
                                        style="clip-path:polygon(23% 0, 100% 0, 100% 100%, 0% 100%);">
                                        <img 
                                            class="workshop-menu-list-img workshop-menu-list-img-2" 
                                            :src="imageUrls['inventory']"
                                            @load="onImageLoad" 
                                            @error="onImageError"
                                            alt="inventory icon" 
                                            style="" >
                                    </button>
                                    <img 
                                            class="workshop-menu-list-img-aura-2" 
                                            :src="require(`@/assets/workshop/aura-right.png`)"
                                            alt="aura icon" style="top: -50%; right: -20%;">
                                </div>
                            </div>
                            <div 
                                class="d-flex" 
                                style="justify-content: center; width: 100%; margin-top: -20px;">
                                <!-- <div 
                                    class="button-element"
                                    style="">
                                    <button 
                                        class="workshop-menu-list pink-button button-element-left" 
                                        id="airdropBtn" 
                                        @click="redirectToPath('AIRDROP')"
                                        style="clip-path: polygon(0 0, 100% 0, 77% 100%, 0% 100%);">
                                        <img 
                                            class="workshop-menu-list-img workshop-menu-list-img-2" 
                                            :src="imageUrls['airdrop']"
                                            @load="onImageLoad" 
                                            @error="onImageError"
                                            alt="airdrop icon" 
                                            style="">
                                    </button>
                                    <img 
                                            class="workshop-menu-list-img-aura-2" 
                                            :src="require(`@/assets/workshop/aura-left.png`)"
                                            alt="aura icon" style="top: -45%; left: -25%;">
                                </div>
                                <div 
                                    class="button-element"
                                    style="">
                                    <button 
                                        class="workshop-menu-list pink-button button-element-right" 
                                        id="claimBtn" 
                                        @click="redirectToPath('CLAIM_REWARD')"
                                        style="clip-path:polygon(23% 0, 100% 0, 100% 100%, 0% 100%);">
                                        <img 
                                            class="workshop-menu-list-img workshop-menu-list-img-2" 
                                            :src="imageUrls['claim']"
                                            @load="onImageLoad" 
                                            @error="onImageError"
                                            alt="claim icon" 
                                            style="" >
                                    </button>
                                    <img 
                                            class="workshop-menu-list-img-aura-2" 
                                            :src="require(`@/assets/workshop/aura-right.png`)"
                                            alt="aura icon" style="top: -50%; right: -20%;">
                                </div> -->
                                <button 
                                    class="workshop-menu-list" 
                                    id="questBtn" 
                                    @click="redirectToPath('ZEALY')"
                                    :disabled="false"
                                    style="">
                                    <img 
                                        class="workshop-menu-list-img" 
                                        :src="imageUrls['quest']"
                                        @load="onImageLoad"
                                        @error="onImageError"
                                        alt="quest icon" style="/*height: 140px;*/position: relative; z-index: 1;">
                                    <img 
                                        class="workshop-menu-list-img-aura" 
                                        :src="require(`@/assets/workshop/aura-blue.png`)"
                                        alt="forge icon" style="/*height: 140px;*/">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <img class="icon-image-brl" :src="imageUrls['brawler']" @load="onImageLoad" @error="onImageError" alt="brawler" style="" />
    </div>
    <div v-if="isLoading" class="loading-content" style="/*padding: 50px 0px;*/" >
        <div class="loading-bar"></div>
    </div>
    <FactoryPopup :totalXP="userXp" :userId="userId" @refreshTotal="fetchData"/>
    <CraftPopup :totalFragment="totalFragment" @refreshTotal="fetchData"/>
    <FittingPopup :userScore="userScore" :isSuitCrafted="isSuitCrafted" @updateScore="updateScore" />
</template>

<script>
import { useStore } from '@/stores/index.ts';
import CraftPopup from "@/components/popup/CraftPopup.vue";
import FittingPopup from "@/components/popup/FittingPopup.vue";
import FactoryPopup from "@/components/popup/FactoryPopup.vue";
import {useToast} from 'vue-toast-notification';
import { ref, computed, watch, onMounted } from 'vue';
import axios from 'axios';
import { db } from '@/firebase.js'; // Import your Firebase DB instance
import { ref as dbRef, onValue, set } from "firebase/database"; // Import necessary Firebase Database functions
import {  sendMessage } from '@/utils/walletUtils';

export default {
    components: {
        CraftPopup,
        FittingPopup,
        FactoryPopup
    }, 
    data() {
        return {
            expireTime: new Date('2025-01-06T18:00:00'),
            timeLeft: {},
            userScore: 0,
            userReward: 0,
            userXp: 0,
            userId: '',
            loading: false,
            error: null,
            totalFragment: [
                { code: 'ITM_FM_MCC', amount: 0 },
                { code: 'ITM_FM_GLB', amount: 0 },
                { code: 'ITM_FM_HLB', amount: 0 },
                { code: 'ITM_FM_FST', amount: 0 }
            ],
            message: '', // Add a reactive message state
            messageLog: [], // To keep a log of messages
            isSuitCrafted: false,
            isLoading: true,
            imageUrls: {'main-bg': require('@/assets/workshop/workshop-bg.png'),
                        'fw-icon': require('@/assets/workshop/fw-icon.png'),
                        'forge': require('@/assets/workshop/forge-button.png'),
                        'outfit': require('@/assets/workshop/outfit-button.png'),
                        'inventory': require('@/assets/workshop/inventory-button.png'),
                        // 'airdrop': require('@/assets/workshop/airdrop-button.png'),
                        // 'claim': require('@/assets/workshop/claim-button.png'),
                        'quest': require('@/assets/workshop/quest-button.png'),
                        'brawler': require('@/assets/workshop/BRL.png'),
                        // 'leaderboard': require('@/assets/workshop/leaderboard-icon.png'),
                        'rewardpool': require('@/assets/workshop/rewardpool-icon.png'),
                        'season': require('@/assets/workshop/season-frame.png'),
                        'score': require('@/assets/workshop/score-frame.png'),
                        'guide': require('@/assets/workshop/guide-icon.png'),
                        'about': require('@/assets/workshop/about-icon.png')},
            totalImages: 0,  // Total number of images
            loadedImages: 0,
            contentHeight: 0
        };
    },
    created() {
        if(this.timeLeft?.timeDifference?? 1 > 0){
            setInterval(this.updateTimeLeft, 1000);
        }
    },
    setup() {
        const store = useStore();
        const isWalletConnected = computed(() => store.getWalletAddress !== '');

        return {
            isWalletConnected
        };
    },
    async mounted() {
        window.scrollTo(0, 0);
        this.totalImages = Object.keys(this.imageUrls).length;
        this.loadedImages = 0;

        const bgImage = new Image();
        bgImage.src = this.imageUrls['main-bg'];
        bgImage.onload = this.onImageLoad;
        bgImage.onerror = this.onImageError;

        const bgImage2 = new Image();
        bgImage2.src = this.imageUrls['season-frame'];
        bgImage2.onload = this.onImageLoad;
        bgImage2.onerror = this.onImageError;

        const bgImage3 = new Image();
        bgImage3.src = this.imageUrls['score-frame'];
        bgImage3.onload = this.onImageLoad;
        bgImage3.onerror = this.onImageError;

        if (this.isWalletConnected) {
            await this.fetchData();
            await this.getXP();
        } else {
            this.$watch('isWalletConnected', async (newVal) => {
                if (newVal) {
                    await this.fetchData();
                    await this.getXP();
                    sendMessage(useStore().getWalletAddress, "entered the workshop");
                }
            });
            // watch(() => this.isWalletConnected, (newVal) => {
            //     if (newVal) {
            //         await this.fetchData();
            //         await this.fetchSuitData();
            //         sendMessage( useStore().getWalletAddress , "entered the workshop");
            //     }
                
            // });
        }

        // Observe messages in the database
        this.observeMessages();

        // Watch for changes in the message and log them
        watch(() => this.message, (newMessage) => {
            if (newMessage) {
                console.log(`Current message: ${newMessage}`);
            }
        });
        
    },
    methods: {
        async getXP(){
            try {
                const wallet = useStore().getWalletAddress;
                const apiUrl = `https://reward-portal.kryptofighters.io/api/quest/get-xp?walletAddress=${wallet}`;

                const response = await axios.get(apiUrl);
                console.log('response getXP ',response);
                this.userXp = (typeof response.data.xp === 'number' && !isNaN(response.data.xp) && response.data.xp > 0)? response.data.xp : 0;
                this.userId = response.data.userId;
            } catch (error) {
                this.error = 'Failed to fetch xp. Please try again later.';
                console.error(error);
            }
        },
        onImageLoad() {
            this.loadedImages += 1;
            this.checkAllImagesLoaded();
        },
        onImageError() {
            this.loadedImages += 1;
            this.checkAllImagesLoaded();
        },
        async checkAllImagesLoaded() {
            console.log(`loaded image: ${this.loadedImages}/${this.totalImages}`);
            if (this.loadedImages === this.totalImages) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                this.isLoading = false;
                await new Promise(resolve => setTimeout(resolve, 500));
                if(!this.isWalletConnected){
                    alert('Please connect your wallet.');
                }
            }
        },
        updateScore(newScore) {
            this.userScore = newScore;
            this.UpdateUserReward();
            this.isSuitCrafted = false;
        },
        UpdateUserReward(){
            if(this.userScore < 60){
                this.userReward = 0;
            }
            else{
                this.userReward = this.userScore/4;
            }
        },
        updateTimeLeft() {
          const now = new Date();
          const timeDifference = this.expireTime - now;

          if (timeDifference <= 0) {
            this.timeLeft.timeDifference = 0;
            this.timeLeft.days = 0;
            this.timeLeft.hours = 0;
            this.timeLeft.minutes = 0;
            this.timeLeft.seconds = 0;

            const svgElement = document.getElementById('time-img-id');
            svgElement.setAttribute('fill', '#c20202');

            return;
          }

          this.timeLeft.timeDifference = timeDifference;
          this.timeLeft.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          this.timeLeft.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          this.timeLeft.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          this.timeLeft.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        },
        async fetchData(_isSuitCrafted=false) {
            this.isSuitCrafted = _isSuitCrafted;
            this.loading = true;
            this.error = null;
            try {
                const fragmentCollection = process.env.VITE_FRAGMENT_CONTRACT_ADDRESS;
                const fragmentRequestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${useStore().getWalletAddress}`],
                        collections: [`KROMA:${fragmentCollection}`]
                    }
                };
                const fragmentApiUrl = `https://api.rarible.org/v0.1/ownerships/search`;
                const fragmentResponse = await axios.post(fragmentApiUrl, fragmentRequestBody, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });

                fragmentResponse.data.ownerships.forEach(nft => {
                    let itemCode = '';
                    switch (nft.tokenId) {
                        case "0": itemCode = 'ITM_FM_HLB'; break;
                        case "1": itemCode = 'ITM_FM_GLB'; break;
                        case "2": itemCode = 'ITM_FM_MCC'; break;
                    }

                    if (itemCode) {
                        const item = this.totalFragment.find(f => f.code === itemCode);
                        if (item) item.amount = Number(nft.value);
                    }
                });
            } catch (error) {
                this.error = 'Failed to fetch data. Please try again later.';
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        observeMessages() {
            // Reference to your messages node in the database
            const messagesRef = dbRef(db, 'messages'); // Change 'messages' to your actual messages path

            // Listen for value changes
            onValue(messagesRef, (snapshot) => {
                const messages = snapshot.val();
                console.log(`observeMessages: ${Object.values(messages)}`);
                if (messages) {
                    this.messageLog = Object.values(messages); // Update message log with new messages
                                  
                    useToast().open({
                      message: this.messageLog,
                      position: 'bottom',
                      type: 'info',
                  });
                }
            });
        },
        redirectToPath(select) {
            const paths = {
                INVENTORY: 'https://app.kryptofighters.io/inventory',
                AIRDROP: 'https://zealy.io/cw/kryptofighters/',
                GUIDE: 'https://link.medium.com/duQlpKpf8Nb',
                LEADERBOARD: 'https://zealy.io/cw/kryptofighters/leaderboard',
                CLAIM_REWARD: 'https://app.kryptofighters.io/reward',
                ZEALY: 'https://zealy.io/cw/kryptofighters',
                REWARD: 'https://app.kryptofighters.io/reward'
            };
            window.open(paths[select], '_blank');
        },
        async fetchSuitData() {
            try {
                const wallet = useStore().getWalletAddress;
                const apiUrl = `https://reward-portal.kryptofighters.io/api/outfit/unequip`;

                // Define headers
                const headers = {
                    'x-api-key': 'Xd710uEYzr' // Replace with your actual API key
                };

                const requestBody = {
                    wallet: "0xDaa8084844e277D8ce109B55661418C8C40FbF83",
                    part: "body",
                    item_id: "1"
                };

                const response = await axios.post(apiUrl, requestBody , { headers });
                console.log('fetchSuitData', response);
            } catch (error) {
                this.error = 'Failed to fetch data. Please try again later.';
                console.error(error);
            }
        },
    }
}
</script>
  
<style scoped>
    @font-face {
        font-family: 'PressStart';
        src: url('/fonts/PressStart.ttf') format('truetype');
    }

    @font-face {
        font-family: 'LilitaOne';
        src: url('/fonts/LilitaOne.ttf') format('truetype');
    }

    @font-face {
        font-family: 'Timmana';
        src: url('/fonts/Timmana.ttf') format('truetype');
    }

    @font-face {
        font-family: "Zing Rust Base";
        src: url("https://db.onlinewebfonts.com/t/ec11ddc9fa834d65ab94debb6af73080.eot");
        src: url("https://db.onlinewebfonts.com/t/ec11ddc9fa834d65ab94debb6af73080.eot?#iefix")format("embedded-opentype"),
        url("https://db.onlinewebfonts.com/t/ec11ddc9fa834d65ab94debb6af73080.woff2")format("woff2"),
        url("https://db.onlinewebfonts.com/t/ec11ddc9fa834d65ab94debb6af73080.woff")format("woff"),
        url("https://db.onlinewebfonts.com/t/ec11ddc9fa834d65ab94debb6af73080.ttf")format("truetype"),
        url("https://db.onlinewebfonts.com/t/ec11ddc9fa834d65ab94debb6af73080.svg#Zing Rust Base")format("svg");
    }

    .content-font {
        font-family: 'Timmana'; 
        /* text-shadow: 
                    -.5px -.5px 0 #FF11D9,
                    .5px -.5px 0 #FF11D9,
                    -.5px .5px 0 #FF11D9,
                    .5px .5px 0 #FF11D9; */
        /* margin-left: 20px; */
        text-align: center;
        font-size: 20px;
    }

    .head-font {
        font-family: 'LilitaOne'; 
        color: #FFDE02;
        text-shadow: 
                    3px 3px 0 black, 
                    .5px -.5px 0 black, 
                    -.5px .5px 0 black, 
                    .5px .5px 0 black;
        text-align: center;
        font-size: 60px;
    }

    .menu-bottom-list-font {
        font-family: 'Timmana'; 
        color: white;
        font-size: 20px;
        font-weight: 900 !important;
        letter-spacing: 1.5px;
    }

    .page-content {
        position: relative; /* Ensure positioning context */ 
        /*height: 100vh - 10px;*/
        /* background-image: url('@/assets/workshop/workshop-bg.png'); */
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    .workshop-menu-list {
        /* padding: 10px 15px;
        background-color: rgba(46, 27, 91, 0.8);
        border: 4px solid #ccc;
        margin: 5px 0;  */
        cursor: pointer;
        transition: background-color 0.3s;
        width: auto;
        height: 140px;
    }

    .workshop-menu-list-img {
        /* max-width: 100%;  */
        height: inherit; 
        display: block;
    }
    
    .workshop-menu-list:hover {
        /* background-color: #a33af4; */
        /* background: linear-gradient(20deg, #df4bd3 20%, #a33af4 60%); */
        transform: scale(1.05);
        transition: transform 0.3s ease;
    }

    .workshop-menu-list:disabled:hover {
        transform: none; /* Prevent scaling on hover */
        cursor: not-allowed; /* Keep not-allowed cursor */
    }

    /* Styles for disabled state */
    .workshop-menu-list:disabled {
        color: darkgray; /* Light grey */
        cursor: not-allowed;
        opacity: 0.6;
    }

    .center-content {
        /* min-height: 400px;  */
        /* width: 33%; */
        flex-direction:column;
        align-items: end; 
        gap: 30px; 
        justify-content: space-between;
        margin-bottom: 50px;
    }

    .menu-content{
        /* width: 100%;  */
        display: flex; 
        /* justify-content: center;  */
        /* align-items: center;  */
        /* margin-top: 20px;  */
        /* margin-bottom: 30px;  */
        gap: 10%;
        flex-direction: column;
        margin: 10px 30px 0 30px;
    }

    .btn-menu{
        width: 220px; 
        height: 330px; 
        transform: skew(-10deg);
        font-size: 30px;
        font-weight: 600;
        background-color: #2c2a3d;
        border: 3px solid #24203a;
        display: flex; 
        flex-direction: column; 
        justify-content: center; 
        align-items: center; 
        gap: 10px;
    }

    .btn-menu:hover{
        border: 3px solid lightgoldenrodyellow;
        background: linear-gradient(135deg, #f526b6, #920bf9);
    }

    .btn-menu-selected{
        border: 3px solid lightgoldenrodyellow;
        background: linear-gradient(135deg, #f526b6, #920bf9);
    }

    .icon-image {
        width: 100px;
    }

    .menu-bottom-content {
        /* width: 100%;  */
        justify-content: end; 
        /* padding: 0 40px; */
        gap: 15px;
        flex-direction: column;
    }

    .menu-bottom-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        /* width: 7%; */
    }

    .menu-bottom-list:hover {
        /* background-color: #a33af4; */
        /* background: linear-gradient(20deg, #df4bd3 20%, #a33af4 60%); */
        transform: scale(1.05);
    }

    .menu-bottom-list:disabled:hover {
        transform: none; /* Prevent scaling on hover */
        cursor: not-allowed; /* Keep not-allowed cursor */
    }

    /* Styles for disabled state */
    .menu-bottom-list:disabled {
        color: darkgray; /* Light grey */
        cursor: not-allowed;
        opacity: 0.6;
    }

    .menu-bottom-list-icon {
        height: 50px;
        margin-bottom: 5px;
        padding: 4px;
    }

    .icon-image-brl {
        width: 360px;
        position: absolute;
        left: 26%;
        transform: translate(-40%, -76%);
    }

    .icon-image-kf {
        width: 260px;
        position: absolute;
        left: 12%;
        transform: translate(-50%, 30%);
    }

    .main-content {
        padding: 10px 20px !important;
    }

    .ul {
        margin: 0;
    }

    .row-button {
        height: 118px;
        /* margin-top: -118px; */
        /* width: 110%; */
    }

    .menu-col {
        width: 100%; 
        height: auto; 
        display: flex; 
        justify-content: space-between; 
        margin-top: 70px;
        padding-left: 15px;
    }

    .workshop-menu-list-img-aura {
        position: absolute; /* Stack images on top of each other */
        top: 0; /* Align to the top */
        left: 0; /* Align to the left */
        width: 170%; /* Ensure both images take up the same space */
        height: auto; /* Maintain aspect ratio */
    }

    .workshop-menu-list-img-aura {
        display: none; /* Hide hover image by default */
    }

    .workshop-menu-list:not(:disabled):hover .workshop-menu-list-img-aura {
        display: block;
        transform: translate(-19%, -25%);
    }

    .workshop-menu-list-img-aura-2 {
        position: absolute; /* Stack images on top of each other */
        width: 160%;
    }

    .workshop-menu-list-img-aura-2 {
        display: none; /* Hide hover image by default */
    }

    /* .workshop-menu-list:not(:disabled):hover + .workshop-menu-list-img-aura {
        display: block; /* Show the hover image on hover 
    } */

    .workshop-menu-list:not(:disabled):hover + .workshop-menu-list-img-aura-2, .workshop-menu-list:not(:disabled):hover + .workshop-menu-list-img-aura {
        display: block; /* Show the hover image on hover */
    }

    .workshop-menu-list:disabled:hover {
        cursor: not-allowed;
    }

    .pink-button {
        height: 100%;
        width: 100%; 
        position: relative; 
        z-index: 1;
    }

    .button-element {
        /* width: 50%;  */
        position: relative; 
        height: 100%
    }

    .button-element-left {
        left: 10%;
    }

    .button-element-right {
        right: 10%;
    }

    .workshop-menu-list-img-2 {
        /* position: absolute; */
        top: 0;
    }

    .loading-bar {
        width: 100%;
        height: 2px;
        background-color: var(--primary-background-color);
        position: relative;
        overflow: hidden;
        border-radius: 0px; 
    }

    .loading-bar:before {
        content: "";   
        position: absolute;
        top: 0;
        left: -100%;             
        width: 100%;            
        height: 100%;           
        background-image: linear-gradient(100deg, #df4bd3 50%, #8322ce 100%);
        animation: loading 2s linear infinite;
    }

    .loading-content {
        min-height: 650px;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        position: relative;
        z-index: 1;
        /* top: -650px; */
        background-color: #100f17;
    }

    .banner {
        position: relative;
        /* height: 160px; */
        height: auto;
        min-width: 300px;
        width: 35%;
        padding: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
        align-self: self-end;
    }

    .banner img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; 
    }

    .banner::after, .banner::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background: #920bf9;
        z-index: -1;
    }

    .banner::before {
        filter: blur(.7rem);
        /* opacity: 0.5; */
    }

    .xp-content {
        align-items: center;
        flex-direction: column;
    }

    .top-menu-list {
        background-color: rgba(0, 0, 0, 0.5); 
        border-radius: 8px; 
        padding: 10px; 
        width: 40%; 
        gap: 10px; 
        height: auto !important; 
        color:#B7CFFF; 
        font-size: 18px; 
        font-weight: 500;
    }

    .marquee-text {
        display: inline-block;
        animation: marquee 20s linear infinite;
    }

    @keyframes marquee {
    0% {
        transform: translateX(300%);
    }
    100% {
        transform: translateX(-100%);
    }
    }

/* Animation definition */
@keyframes loading {
  0% {
    left: -100%;            /* Start off-screen to the left */
  }
  100% {
    left: 100%;             /* Move fully to the right */
  }
}

@media (max-width: 768px) {
    .main-content {
        padding: 50px 0 0 0 !important;
    }

    .btn-menu{
        width: 132px; 
        height: 198px; 
        transform: skew(-10deg);
        font-size: 20px;
        font-weight: 600;
    }

    .icon-image {
        width: 50px;
    }

    .icon-image-kf {
        width: 180px; 
        transform: translate(-15%, 20%);
    }

    .menu-content {
        margin: 80px 10px 0 10px;
    }

    .head-font {
        font-size: 40px;
    }

    .content-font {
        font-size: 1rem;
    }

    .icon-image-brl {
        display: none;
    }

    .menu-bottom-list {
        width: 50%;
    }

    .menu-bottom-content {
        gap: 0px;
        flex-wrap: wrap;
        margin-top: 20px;
        flex-direction: unset;
    }

    .menu-col {
        justify-content: center; 
        flex-direction: column;
        padding-left: 0;
        align-items: center;
        margin-top: 30px;
    }

    .workshop-menu-list {
        height: 100px;
    }

    .row-button {
        height: 78px;
    }

    .page-content {
        margin-top: 40px;
    }

    .center-content {
        gap: 20px; 
        align-items: center; 
        /* width: 65%; */
    }

    .pink-button {
        height: 100% !important;
    }

    .button-element-left {
        left: 5%;
    }

    .button-element-right {
        right: 5%;
    }

    .banner {
        /* height: 120px; */
        align-self: unset;
    }

    .fragment-content {
        flex-direction: column;
    }

    .xp-content {
        align-items: end;
    }

    .top-menu-list {
        font-size: 14px; 
    }
}

</style>