<template>
  <div class="page-content content-wrap">
    <div class="main-content">
      <div class="d-flex align-items-center justify-content-center">
        <h1>Sign Up</h1>
      </div>
      <div class="d-flex" style="padding: 30px; gap: 50px; flex-direction: column;">
        <div class="d-flex align-items-center justify-content-center" style="flex-direction: column;">
          <h6 v-if="!isWalletConnect" style="padding: 30px 0; text-align: center;">Please connect your wallet to continue</h6>
          <div class="d-flex align-items-center justify-content-center btn-group-custom" style="">
            <button 
              class="btn btn-custom d-flex justify-content-center align-items-center" 
              :class="{ 'btn-primary': isWalletConnect, 'btn-secondary': !isWalletConnect }"
              :style="{ 'pointer-events': isWalletConnect ? 'none' : 'auto' }"
              @click="clickConnect">
              <img
                v-if="isWalletConnect"
                src="../assets/class-users-default.png"
                style="padding: 0px; width: auto; height: 36px; margin-right: 6px;"
                alt="Sample Image"
              >
              <div style="">{{ getConnectWallet() }}</div>
            </button>
            <button v-if="isWalletConnect"
              class="btn btn-custom d-flex justify-content-center align-items-center" 
              :class="{ 'btn-primary': isEmailConnect, 'btn-secondary': !isEmailConnect }"
              :style="{ 'pointer-events': isEmailConnect ? 'none' : 'auto' }"
              data-bs-toggle="modal"
              data-bs-target="#linkAccountPopup"
              >
              <div style="">{{ getConnectEmail() }}</div>
            </button>
            <!-- <button v-if="isWalletConnect" 
              class="btn btn-secondary btn-custom" 
              data-bs-toggle="modal"
              data-bs-target="#linkAccountPopup"
              @click="clickLinkEmail"
              style="">
              Link Email
            </button> -->
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center" style="flex-direction: column;">
          <h6 style="padding: 10px 0; text-align: center;">Game Install</h6>
          <div class="d-flex align-items-center justify-content-center  btn-group-custom" style="">
            <button class="btn btn-secondary btn-custom" style="" @click="navigateToiOS">iOS</button>
            <button class="btn btn-secondary btn-custom" style="" @click="navigateToAndroid">Android</button>
            <button class="btn btn-secondary btn-custom" style="" @click="navigateToUrl">PC</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LinkAccountPopup :access-token="accessToken" :refCode="this.$route.query.aff" @refreshData="RegistrationInquiry" />
  </template>
  
  <script>
  import { useStore } from '@/stores/index.ts';
  import { shortenedWalletAddress , eventBus } from '@/utils/walletUtils.js';
  import { kfServiceStore } from '@/stores/kfServiceStore.ts';
  import { watch } from 'vue';
  import axios from 'axios';

  import LinkAccountPopup from '@/components/popup/LinkAccountPopup.vue'; 

  export default {
    components: {
        LinkAccountPopup,
    },
    props: {
    },
    data() {
        return {
          accessToken: '',
          isRecorded: false,
          isEmailConnect: false,
          email: ''
        }
    },
    computed: {
      isWalletConnect() {
        const store = useStore();
        console.log('isWalletConnect'+store.getWalletAddress);
        return store.getWalletAddress !== '';
      }
    },
    watch: {
      isWalletConnect(newValue) {
        console.log('isWalletConnect'+newValue);
        this.getConnectWallet();
      }
    },
    async mounted(){
      console.log('useStore().getWalletAddress' +useStore().getWalletAddress);
      this.Authen().then(() => {
            if(useStore().getWalletAddress!=''){
              this.RegistrationInquiry();
            }else{
              alert('Please connect your wallet.');
              watch(() => useStore().getWalletAddress, () => {
                  this.RegistrationInquiry();
              });
            }
        });
    },
    methods: {
      async Authen() {
        const store = kfServiceStore();
        await store.fetchAccessToken();
        this.accessToken = store.accessToken;
        //console.log("accessToken" , this.accessToken);
      },
      async RegistrationInquiry() {
        console.log('RegistrationInquiry');
        try {
            const apiUrl = 'https://service.kryptofighters.io/api/user/registration-inquiry';

            const requestBody = {
                walletAddress: useStore().getWalletAddress,
                refTransId: this.RandomRefTransID()
            };

            const response = await axios.post(apiUrl, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.accessToken}`
                }
            });

            console.log('Response data:', response.data);

            this.email = response.data.email;

            if(response.data.reponseCode === '200'){
              this.isEmailConnect = false;
            }
            else{
              this.isEmailConnect = true;
            }
        } catch (error) {
            console.error('Error:', error);
        }
      },
      async recordReferral(){
        this.isRecorded = true; return;
        console.log('recordReferral');
        try { 
            const wallet = useStore().getWalletAddress;
            const apiUrl = `https://reward-portal.kryptofighters.io/api/referral/record-referral`;
            
            const requestBody = {
              refCode: this.$route.query.aff,
              walletAddress: wallet
            };

            const response = await axios.post(apiUrl, requestBody);
            console.log('response recordReferral ',response);
            
        } catch (error) {
            this.error = 'Failed to fetch xp. Please try again later.';
            console.error(error);
        }
      },
      RandomRefTransID(){
        let refID = '';

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = 0; i < 5; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            refID += characters.charAt(randomIndex);
        }

        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\//g, '');

        refID += formattedDate;
        refID += useStore().getWalletAddress.slice(-5);
        
        console.log(refID);

        return refID;
      },
      getConnectWallet(){
        if(this.isWalletConnect){
          if(this.isRecorded === false){
            this.recordReferral();
          }
          
          const store = useStore();
          return shortenedWalletAddress(store.getWalletAddress);
        }
        else{
          return "Connect Wallet";
        }
      },
      getConnectEmail(){
        if(this.isEmailConnect){
          return this.email;
        }
        else{
          return 'Link Email';
        }
      },
      clickConnect(){
        eventBus.emit('connectWallet');
      },
      // async clickLinkEmail(){
      //   const store = kfServiceStore();
      //     await store.fetchAccessToken();
      //     this.accessToken = store.accessToken;
      //     return store.accessToken;
      // },
      navigateToUrl() {
        window.open('https://elixir.games/browse/krypto-fighters', '_blank');
      },
      navigateToiOS() {
        window.open('https://testflight.apple.com/join/odnfQIkv', '_blank');
      },
      navigateToAndroid() {
        window.open('https://play.google.com/store/apps/details?id=com.maxma.kryptofighters', '_blank');
      },
    },
  };
                      
  </script>
  
  <style scoped>
  .btn-custom {
    width: 100%; 
    padding: 10px;
  }

  .btn-group-custom {
    flex-direction: column; 
    width: 25%; 
    gap: 15px;
    min-width: 200px;
  }

  @media (max-width: 768px) {
    .btn-group-custom {
      width: 80%; 
    }
  }
  
  
  </style>