<template>
    <div class="page-content content-wrap">
      <div class="main-content">
        <div class="d-flex justify-content-between" style="flex-direction: column;">
          <h1>Referral Program</h1>
          <span>Invite friend to get exclusive rewards and earn up to 40% commission every time<br>your friend make trade in KryptoFighters platform</span>
        </div>
        <div class="d-flex referral-content" style="">
          <div class="left-half-content" style="">
            <div class="d-flex" style="flex-direction: column; width: 100%; gap: 40px;">
              <div class="d-flex" style="gap: 10px; flex-direction: column;">
                <div v-if="isAccountLinked">
                  <h8>Email</h8>
                  <div class="d-flex justify-content-between align-items-center data-frame" style="background-color: #282c34; border-color: #3c424d;">
                    <span style="padding-left: 10px; color: #8e97a8;"> {{ email }} </span>
                  </div>
                </div>
                <h8>Use your referral code</h8>
                <div v-if="isAccountLinked" class="d-flex justify-content-between align-items-center data-frame" style="">
                  <span style="padding-left: 10px;"> {{ referralData?.referralCode?? '...' }} </span>
                  <div style="position: relative; display: inline-block;">
                    <button class="btn-copy" @click="copyText" style="border: 1px solid white; border-radius: 8px; padding: 3px 5px;">
                      Copy
                    </button>
                    <div v-if="isCopyClicked" class="popup-message">
                      Copied!
                    </div>
                  </div>
                </div>
                <div v-else class="d-flex align-items-center" style="gap: 20px;">
                  <span style="color: #c80000;">Please link your account</span>
                  <a
                    href="https://app.kryptofighters.io/account_setting"
                    target="_blank"
                    style="text-decoration: none; color: white;">
                    <div class="" style="border: 1px solid white; border-radius: 8px; padding: 5px 10px;">
                      Link Account
                    </div>
                  </a>
                </div>
              </div>
              <div class="d-flex" style="gap: 10px; flex-direction: column;">
                <div class="d-flex" style="flex-direction: column; gap: 10px;">
                  <h8>Your Score : {{ referralData?.totalReferrals?? 0 }} / {{ referralData?.totalSuccessPoints?? 0 }} </h8>
                  <div class="d-flex" style="gap: 10px; flex-direction: column;">
                    <h8>Affiliates :</h8>
                    <div v-for="aff in arffilates" class="d-flex justify-content-between align-items-center data-frame" style="">
                      <span style="padding-left: 10px;">{{ getWallet(aff.walletAddress) }}</span>
                      <div class="" style="padding: 3px 5px;">
                        trade vol
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-half-content" style="">
            <div class="d-flex align-items-center" style="width: 100%; flex-direction: column; gap: 30px;">
              <h8>Rewards</h8>
              <div class="d-flex justify-content-between align-items-center reward-content" style="">
                <div v-for="reward in rewards" class="d-flex justify-content-center align-items-center reward-item" style="">
                  <p class="item-text" style="">
                    {{ reward.point }} Point
                  </p>
                  <div class="d-flex justify-content-center align-items-center" style="border: 1px solid white; border-radius: 8px;">
                    <!-- <img class="electric-bg" width="80" :src="`../images/item/pack/${reward.code}.png`" alt="electric bg" style="object-fit: cover;padding: 10px;" /> -->
                    <div class="d-flex justify-content-center" style="width: 100px; height: 100px; padding-top: 10px; padding-bottom: 10px; padding-right: calc((100% - auto) / 2); padding-left: calc((100% - auto) / 2); position: relative;">
                      <img class="electric-bg" :src="`../images/item/pack/${reward.code}.png`" alt="electric bg" style="height: 100%; width: auto; object-fit: cover;" />
                    </div>
                  </div>
                  <p class="item-text" style="">
                    {{ reward.name }}  
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import { watch } from 'vue';
import { useStore } from '@/stores/index.ts';
import { kfServiceStore } from '@/stores/kfServiceStore.ts';
import { useToast } from 'vue-toast-notification';
import { shortenedWalletAddress } from '@/utils/walletUtils.js';

export default {
    components: {
    },
    props: {
      
    },
    data() {
        return {
          accessToken: '',
          email: '',
          referralData: {},
          isAccountLinked: false,
          isCopyClicked: false, 
          rewards: [{id:1, code:'alpha', point: 100, name: 'Alpha Pack'},
                    {id:2, code:'welcome', point: 500, name: 'Welcome Pack'},
                    {id:3, code:'genesis', point: 1000, name: '1000 SKF Airdrop'}],
          arffilates: []
        }
    },
    async mounted(){
      console.log('useStore().getWalletAddress' +useStore().getWalletAddress);
      this.Authen().then(() => {
            if(useStore().getWalletAddress!=''){
              this.RegistrationInquiry();
            }else{
              alert('Please connect your wallet.');
              watch(() => useStore().getWalletAddress, () => {
                  this.RegistrationInquiry();
              });
            }
        });
    },
    methods: {
      async Authen() {
        const store = kfServiceStore();
        await store.fetchAccessToken();
        this.accessToken = store.accessToken;
        //console.log("accessToken" , this.accessToken);
      },
      async RegistrationInquiry() {
        console.log('RegistrationInquiry');
        try {
            const apiUrl = 'https://service.kryptofighters.io/api/user/registration-inquiry';

            const requestBody = {
                walletAddress: useStore().getWalletAddress,
                refTransId: this.RandomRefTransID()
            };

            const response = await axios.post(apiUrl, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.accessToken}`
                }
            });

            console.log('Response data:', response.data);

            this.email = response.data.email;

            if(response.data.reponseCode === '200'){
              this.isAccountLinked = false;
            }
            else{
              this.isAccountLinked = true;
              await this.getRefCode();
              await this.getArffilates();
            }
        } catch (error) {
            console.error('Error:', error);
        }
      },
      async getRefCode() {
        if(!this.email){
          useToast().open({
              message: 'No email',
              position: 'top-right',
              type: 'error',
          });
          return;
        }

        try {
            const response = await axios.get(`https://reward-portal.kryptofighters.io/api/referral/get-refcode?email=${this.email}`);

            console.log('Response data:', response.data);

            this.referralData = response.data;
        } catch (error) {
            console.error('Error:', error);
        }
      },
      async getArffilates() {
        if(!this.email){
          useToast().open({
              message: 'No email',
              position: 'top-right',
              type: 'error',
          });
          return;
        }

        try {
            const response = await axios.get(`https://reward-portal.kryptofighters.io/api/referral/get-arffilates?email=${this.email}`);

            console.log('Response data:', response.data);
            this.arffilates = response.data.arffilates;
        } catch (error) {
            console.error('Error:', error);
        }
      },
      RandomRefTransID(){
        let refID = '';

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = 0; i < 5; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            refID += characters.charAt(randomIndex);
        }

        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\//g, '');

        refID += formattedDate;
        refID += useStore().getWalletAddress.slice(-5);
        
        console.log(refID);

        return refID;
      },
      copyText() {
        console.log('copyText');
        const textToCopy = this.referralData.referralCode;
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        this.isCopyClicked = true;
        console.log('copyText');

        setTimeout(() => {
          this.isCopyClicked = false;
        }, 1000);
      },
      getWallet(aff){
        return shortenedWalletAddress(aff);
      }
    }
};           
</script>

<style scoped>
  h8 {
    font-weight: 500;
  }

  .referral-content {
    width: 100%; 
    padding: 50px;
  }

  .left-half-content {
    width: 40%;
  }

  .right-half-content {
    width: 50%;
  }

  .data-frame {
    border: 1px solid white; 
    border-radius: 8px; 
    padding: 10px; 
    width: 55%;
  }

  .reward-content {
    width: 100%; 
    padding: 0 50px;
  }

  .reward-item {
    flex-direction: column; 
    gap: 10px; 
    width: 100px;
  }

  .item-text {
    text-align: center;
    white-space: nowrap;
  }

  p {
    margin: 0;
  }

  .btn-copy:hover {
    background-color: #2c2a3d;
  }

  .popup-message {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    /* background-color: rgba(0, 0, 0, 0.7); */
    background: linear-gradient(135deg, #df4bd3, #a33af4);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    opacity: 0;
    animation: slideUp 0.5s ease-out forwards; 
  }

  @keyframes slideUp {
    0% {
      transform: translateX(-50%) translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateX(-50%) translateY(-15px); 
      opacity: 1;
    }
  }

@media (max-width: 768px) {
    .referral-content {
      flex-direction: column;
      gap: 40px;
    }

    .left-half-content, .right-half-content, .data-frame {
      width: 100%;
    }

    .reward-content {
      padding: 0;
      flex-direction: column;
      gap: 20px; 
    }

    .reward-item {
      flex-direction: unset; 
      justify-content: space-between;
      width: auto;
    }

    .item-text {
      width: 100px;
      white-space: unset;
    }
}


</style>