<template>
  <div
    id="editScholarshipPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="editScholarshipPopup"
    aria-hidden="true"
    @click.self="cancelEditData"
    :style="{ 'pointer-events': isLoading ? 'none' : 'auto' }">
    <div class="modal-dialog">
      <div class="modal-content" style="min-width: 400px;">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">
            {{ (localTitle == '')? localData.name : localTitle }}
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cancelEditData"
            :disabled="isLoading"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="updateEditData" class="d-flex justify-content-center align-items-center" style="flex-direction: column; gap: 30px;">
            <div class="d-flex justify-content-center" style="gap: 10px; width: 100%; flex-direction: column;">
              <div id="edit_name" class="d-flex align-items-center" style="gap: 10px; width: 100%;">
                <span class="text-field" style="">Pack Name : </span>
                <input
                  id="editName"
                  v-model="localData.name"
                  type="text"
                  class="input-field" 
                  placeholder=""
                  maxlength="30"
                  required
                >
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div id="edit_share" class="d-flex align-items-center " style="gap: 10px;">
                  <span class="text-field" style="">Share rate : </span>
                  <input
                    id="editShare"
                    v-model="localData.share_rate"
                    type="number"
                    class="input-field" 
                    placeholder=""
                    required
                    min="0"
                    max="100"
                    style="width: 30%;"
                  >
                  <span class="text-field" style="width: auto;">%</span>
                </div>
                  <label class="switch">
                    <input type="checkbox" :checked="localData.type" @change="toggleChanged(localData.type)" v-model="localData.type" value="localData.type">
                    <span class="slider round"></span>
                  </label>
              </div>
              
            </div>
            <button class="btn btn-primary" type="submit" style="width: fit-content; min-width: 120px;" :disabled="isLoading">
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else> Confirm </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useStore } from '@/stores/index.ts';
import axios from 'axios';
import { useToast } from 'vue-toast-notification';

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    showModal: Boolean
  },
  data() {
      return {
        localTitle: '',
        localData: { ...this.data },
        isLoading: false
      };
  },
  watch: {
    data: {
      handler(newVal) {
        console.log('Message prop updated:', newVal);
        this.localData = { ...newVal };
        console.log('Message prop updated: this.localData ', this.localData);
      },
      deep: true
    },
    title: {
      handler(newVal) {
        console.log('Message prop updated:', newVal);
        this.localTitle = newVal;
      },
      deep: true
    }
  },
  methods: {
    closeModal() {
      console.log('closeModal');
      this.$refs.Close.click();
    },
    cancelEditData(){
      this.localData = { ...this.data };
      this.closeModal();
    },
    async updateEditData(){
      console.log("updateEditData"+ this.data.id);
      if(this.data.id !== -1){
        console.log("updateEditData"+ this.data.id);
        await this.updatePack();
      }
      else{
        await this.addPack();
      }
      console.log("updateEditData");
      delete this.localData.title;
      this.closeModal();
    },
    toggleChanged(type){
      console.log('toggleChanged'+ this.localData.type);
    },
    async updatePack(){
      this.isLoading = true;
        try {
            const accountAddress = useStore().getWalletAddress;
            const apiUrl = `https://reward-portal.kryptofighters.io/api/scholar/pack/update`;

            const updateFields = {};
            if (this.data.name != this.localData.name) {
                updateFields.scholar_name = this.localData.name;
            }

            if (this.data.type != this.localData.type) {
                updateFields.team_type = Number(this.localData.type);
            }

            if (this.data.share_rate != this.localData.share_rate) {
                updateFields.percent_share = this.localData.share_rate;
            }

            if(Object.keys(updateFields).length > 0){
              const requestBody = {
                wallet: accountAddress,
                team_code: this.data.code,
                ...updateFields
              };
              console.log('requestBody',requestBody);
              const response = await axios.post(apiUrl, requestBody);
              console.log("gen1Response ",response);
            }
            this.isLoading = false;
            // setTimeout(() => {
            //   this.updateEditData();
            // }, 100);
            useToast().open({
                        message: 'Pack updated successfully.',
                        position: 'top-right',
                        type: 'success',
                    });
            this.$emit('updateEditData', this.localData);
        } catch (error) {
            console.error('Error fetching data from OpenSea:', error);
            const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
            useToast().open({
                    message: 'Update error : ' + errorMessage,
                    position: 'top-right',
                    type: 'error',
                });
            this.isLoading = false;
        }
    },
    async addPack(){
      this.isLoading = true;
        try {
            const accountAddress = useStore().getWalletAddress;
            const apiUrl = `https://reward-portal.kryptofighters.io/api/scholar/pack/add`;

            const requestBody = {
                wallet: accountAddress,
                scholar_name: this.localData.name, 
                team_type: Number(this.localData.type), 
                percent_share: this.localData.share_rate
              };
            console.log('requestBody',requestBody);
            const response = await axios.post(apiUrl, requestBody);
            console.log("gen1Response ",response);
            this.localData.id = response.data.data._id;
            this.localData.code = response.data.data.team_code;
            this.isLoading = false;
            // setTimeout(() => {
            //   this.updateEditData();
            // }, 100);
            useToast().open({
                        message: 'Pack insert successfully.',
                        position: 'top-right',
                        type: 'success',
                    });
            this.$emit('updateEditData', this.localData);
        } catch (error) {
            console.error('Error fetching data from OpenSea:', error);
            const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
            useToast().open({
                    message: 'Update error : ' + errorMessage,
                    position: 'top-right',
                    type: 'error',
                });
            this.isLoading = false;
        }
    }
  },
};
</script>
  
<style scoped>
/* .modal-overlay {
display: none;
}

.modal.fade.show .modal-overlay {
display: block;
} */
.input-field {
    border: 1px solid #3c424d;
    appearance: none;
    background-clip: padding-box;
    border-radius: 8px;
    display: block;
    outline: none;
    padding: 5px 10px;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    width: 70%;
}

.text-field {
  font-weight: 500;
  white-space: nowrap;
  width: 100px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  width: 100px;
  background-color: #2196F3;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #df4bd3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #df4bd3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(65px);
}

.slider::after {
  content: "Public";
  position: absolute;
  top: 50%;
  right: 15px; 
  transform: translateY(-50%);
  font-size: 14px;
  color: white;
  font-weight: bold;
}

input:checked + .slider::after {
  content: "Private"; 
  left: 15px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
  