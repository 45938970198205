<template>
  <div
    id="factoryPopup"
    class="modal fade no-padding"
    tabindex="-1"
    aria-labelledby="factoryPopup"
    aria-hidden="true"
    @click.self="selectButton"
  >
    <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width: 500px;">
      <div class="modal-content" style="background-color: #151030; border: 1px solid #7A37E6; border-radius: 8px;">
        <div class="modal-header">
          <div style="background: linear-gradient(20deg, rgb(248, 4, 156) 20%, rgb(74, 31, 187) 80%); height: 0.5em; width: 100%; border-radius: inherit;"></div>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="selectButton"
            style="margin: 0.25em 0.5em; padding: 0;"
          />
        </div>
        <div class="modal-body" style="padding: 0;">
          <div class="d-flex align-items-center justify-content-center" style="margin-top: -10px;">
            <img class="electric-bg" :src="require(`@/assets/workshop/electric-bg.png`)" alt="electric bg" style="height: 90px; position: relative;" />
            <div class="d-flex align-items-center justify-content-center" style="position: absolute; z-index: 1; flex-direction: column;">
              <img class="electric-bg" :src="require(`@/assets/workshop/factory-header.png`)" alt="electric bg" style="height: 40px;" />
              <div style="font-size: 18px; font-weight: 500;">
                Mint Fragment NFTs with XP
              </div>
            </div>
          </div>
          <div style="padding: 0 20px 10px 20px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <div class="d-flex justify-content-center align-items-center" style="margin: 0 30px; background-color: #101327; width: 100%; border-radius: 8px; flex-direction: column;">
              <!-- <div style="color: #B6B6BB; margin: 5px; font-weight: 500;">material</div> -->
              <div class="d-flex justify-content-center align-items-center" style="gap: 20px; margin: 30px 0 40px 0; height: 150px;">
                <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                  <img class="material-icon" :src="`../images/item/XP.png`" alt="material 1 icon" style="" />
                  <div class="detail-text width-detail-text">
                    <span id="amountId">{{ xp }}</span>&nbsp;/&nbsp;{{ useXP }}
                    <!-- <span id="amountId">{{ getMatetialAmount(getMaterialUse()?.code?? '') }}</span>&nbsp;/&nbsp;{{ materialUseTotal }} -->
                  </div>
                  <div class="" style="background-color: black;padding: 0px 20px; font-size: 14px; font-weight: 400; margin-top: 10px; border-radius: 8px;"> 
                    Get XP
                  </div>
                </div>
                <img width="20" :src="`../images/arrow-icon.png`" alt="icon" style="" />
                <div class="d-flex justify-content-start align-items-center" style="flex-direction: column; height: 100%;">
                  <img class="material-icon" :src="`../images/item/ITM_FM_MCC.png`" alt="material 1 icon" style="" />
                  <div id="resultAmountId" class="detail-text width-detail-text">
                    {{ craftAmount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-end" style="width: 100%; margin: 10px 0 20px 0; justify-content: center;">
              <!-- <div class="detail-text" style="background-color: black; width: 100px; height: 80%;"> total: </div> -->
              <div class="d-flex justify-content-center align-items-center" style="height: 100%; gap: 10px; flex-direction: column;">
                <div class="d-flex justify-content-center align-items-center" style="height: 40px; gap: 10px;">
                  <div class="d-flex justify-content-center align-items-center" style="width: auto; height: 80%;">
                    <button 
                      :class="['amount-content', 'btn-decrease', { 'btn-disabled': isDecreaseDisable }]" 
                      :disabled="isDecreaseDisable" 
                      @click="updateCraftAmount(false)" 
                      style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;"> - 
                    </button>
                    <span class="amount-content d-flex justify-content-center align-items-center" style=""> {{ craftAmount }} </span>
                    <button 
                      :class="['amount-content', 'btn-increase', { 'btn-disabled': isIncreaseDisable }]" 
                      :disabled="isIncreaseDisable" 
                      @click="updateCraftAmount(true)" 
                      style="border-top-right-radius: 8px; border-bottom-right-radius: 8px;"> + 
                    </button>
                  </div>
                  <button class="detail-text" :class="['amount-content', 'btn-increase', { 'btn-disabled': isCraftDisable }]" :disabled="isCraftDisable" @click="getMaxCraft()" style="background-color: black; width: 50px; height: 80%; padding: 0 10px;"> max </button>
                </div>
                <button :class="['btn', 'btn-primary', { 'btn-disabled': isCraftDisable }]" :disabled="false" @click="mint" style="background-color: black; padding: 0 50px; height: 40px; font-size: 20px; font-weight: 500;"> 
                  <div v-if="isLoading">
                    <i class="fa fa-circle-o-notch fa-spin" />
                  </div>
                  <div v-else>
                    Mint
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
  <TransactionPendingPopup :message="message" />
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { getSignature } from '@/utils/web3Utils';
import NFTContractABI from '@/contracts/NFTContractABI.json';
import {  sendMessage } from '@/utils/walletUtils';
import {useToast} from 'vue-toast-notification';
import { ref, onMounted, watch } from 'vue';
import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";

export default {
    components: {
      TransactionPendingPopup
    },
    props: {
        showModal: Boolean,
        totalXP: {
          type: Number,
          required: true 
        },
        userId: {
          type: String,
          required: true 
        }
    },
    data() {
        return { 
          isLoading: false,
          score: 0,
          xp: 0,
          useXP: 10,
          craftAmount: 1,
          isDecreaseDisable: false,
          isIncreaseDisable: true,
          isCraftDisable: true
        };
    },
    watch: {
      totalXP(newVal) {
          this.xp = newVal;
          this.updateButton();
      }
    },
    async mounted() {
      this.xp = this.totalXP;
    },
    methods: {
      closeModal(){
        console.log('closeModal');
        // this.$refs.Close.click();
        this.$emit('refreshTotal');
      },
      updateCraftAmount(isIncrease){
        if (isIncrease) {
          this.craftAmount++; // Increment
        } else {
          this.craftAmount--; // Decrement
        }

        this.useXP = 10*this.craftAmount;
        
        this.updateButton();
      },
      updateButton(){
        if(this.craftAmount == 1){
          this.isDecreaseDisable = true;
        }
        else{
          this.isDecreaseDisable = false;
        }

        const maxResult = Math.floor(this.xp / 10); 
        if(this.craftAmount == maxResult){
          this.isIncreaseDisable = true;
        }
        else{
          this.isIncreaseDisable = false;
        }

        const amountIdElement = document.getElementById('amountId');
        if(this.xp < this.useXP){
          this.isCraftDisable = true;
          this.isIncreaseDisable = true;
          this.isDecreaseDisable = true;
          amountIdElement.style.color = 'red';
        }
        else{
          this.isCraftDisable = false;
          amountIdElement.style.color = 'lightgreen';
        }
      },
      getMaxCraft(){
        const maxResult = Math.floor(this.xp / 10); 

        this.craftAmount = maxResult;
        this.useXP = 10*this.craftAmount;
        this.updateButton();
      },
      async mint(){
        this.isCraftDisable = true;
        this.isLoading = true;

        if(this.xp < this.useXP){
        // if(false){
          useToast().open({
                      message: `Error: Can not craft Microship.`,
                      position: 'top-right',
                      type: 'error',
                  });
        }
        else{
          if(this.userId){
            this.message = {
                title: `Crafting Microship`,
                image: require('@/assets/images/crafting-loading.gif'),
                text: "Go to your wallet <br> You'll be asked to approve this transfer from your wallet."
            }

            $(document).ready(function() {
              $('#transactionPendingPopup').modal('show');
            });
            const audioForge = new Audio(require('@/assets/sound/SFX_Forge.wav'));
            // audioForge.loop = true;
            audioForge.play(); 
          
            try{
              const wallet = useStore().getWalletAddress;
              if(wallet){
                const apiUrl = `https://reward-portal.kryptofighters.io/api/quest/claim-microchip`;

                const requestBody = {
                    userId: this.userId,
                    walletAddress: wallet,
                    amount: this.craftAmount
                };

                console.log("requestBody ",requestBody);

                const response = await axios.post(apiUrl, requestBody);
                console.log(response);
                this.xp -= this.useXp;
                console.log(this.xp);
                this.updateButton();
                // }
                const audioComplete = new Audio(require('@/assets/sound/SFX_QuestReward.mp3'));
                audioComplete.play(); 
                useToast().open({
                        message: `Craft ${this.selectedRecipe.name} completed.`,
                        position: 'top-right',
                        type: 'success',
                    });
              }
            } catch (error) {
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                console.error('Error deposit:', error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                
                // alert('Please refresh your browser.');
            }
          }
        }
        this.isCraftDisable = false;
        this.isLoading = false;
      }
    }
};
</script>

<style scoped lang="scss">
  body.modal-open {
    overflow: hidden;
  }

  .modal-dialog {
    overflow: hidden;
  }

  .no-padding {
    padding: 0 !important;
    overflow: unset;
  }

  .modal-header {
    border-bottom: none;
    padding: 0;
    flex-direction: column;
    align-items: end;
    position: relative;
    z-index: 2;
  }
  
  .dropdown-filter-toggle {
    background-color: #101327;
  }

  .modal-title {
    text-align: center;
    width: 100%;
    font-weight: 600;
  }

  .dropdown-filter-toggle.active {
    background-color: #ff65fc55;
    border-color: #ff65fa;
    color: white;
  }

  .detail-text {
    color: white;
    display: inline-flex;
    align-items: center;
    background-color: var(--light-background-color);
    border-radius: 8px;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    width: 60px;
    font-weight: 500;
  }

  .width-detail-text{
    width: 100px;
  }

  .material-icon {
    width: 100px;
  }

  .btn-decrease {
    background-color: black;
  }

  .btn-increase {
    background-color: black;
  }

  .btn-decrease:hover:not(.btn-disabled){
    background-color: darkred;
  }

  .btn-increase:hover:not(.btn-disabled) {
    background-color: darkgreen;
  }

  .btn-disabled{
    background-color: #e0e0e0; /* Grey */
    color: darkgray; /* Light grey */
    cursor: not-allowed;
    opacity: 0.6;
  }

  .amount-content {
    width: 40px; 
    height: 100%; 
    background-color: black;
    color: white;
  }

  .item-container {
    display: inline-grid;
    grid-template-columns: repeat(4, calc(25% - 5px));
    width: 100%;
    grid-gap: 5px;
    // height: inherit;
    overflow-y: scroll;
  }

  .item {
    width: auto; /* Fixed width for each item */
    height: auto; /* Fixed height for each item */
    box-sizing: border-box; /* Ensure padding and border are included in width */
    text-align: center;
    // padding: 5px 5px; 
    cursor: pointer; 
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .item.active {
    background-color: #ff65fc55;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
  }
  
  .item-image {
    width: auto;
    max-width: 50px;
  }

  .selected-suit {
    flex-direction: column; 
    height: 80px; 
    width: 80px;
  }

  .content-box {
    background-color: #241D4F; 
    border-radius: 8px;
  }

  .pentagon-container {
    position: relative;
    width: 200px;
    height: 200px;
    margin: auto;
  }
  .pentagon {
    position: relative;
    width: 100%;
    height: 100%;
    clip-path: polygon(
      50% 0%,
      100% 38%,
      82% 100%,
      18% 100%,
      0% 38%
    );
    background-color: transparent; /* Optional: set to your desired color */
    border: 2px solid #644FC0; /* Optional: border style */
  }

  .selected-content {
    width: 100%; 
    gap: 15px;
  }

  .half-selected-content {
    width: 50%; 
    flex-direction: column;
    gap: 10px;
  }

  .cobweb-img {
    width: 90%;
  }

  .select-suit-content {
    background-color: #151030 !important; 
    height: 260px; 
    padding: 10px;
    padding-right: 5px;
    min-height: 200px;
  }

  .benefit-text {
    color: #FFD600; 
    font-weight: 400; 
    font-size: 14px; 
    padding: 10px 0;
  }

  .select-suit-blog {
    padding: 15px 0; 
    flex-direction: column;
  }

  .polygon-image {
    width: 60%;
  }

  .cobweb-blog {
    margin: 0;
  }

  .pentagon-image {
    height: 50%; 
    width: 50%;
  }

@media (max-width: 768px) {
    .no-padding {
      padding: 0 !important;
      overflow: auto;
    }

    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }

    .material-icon {
      width: 70px;
    }

    // .item-container {
    //   grid-template-columns: 50% 50%;
    // }

    .selected-suit {
      width: 100px;
    }

    .selected-content {
      flex-direction: column;
    }

    .half-selected-content {
      width: 100%;
    }

    .cobweb-img {
      width: 50%;
    }

    .benefit-text {
      font-size: 14px; 
      padding: 10px 0;
    }

    .select-suit-blog {
      padding: 25px 0; 
    }

    .polygon-image {
      width: 80%;
    }

    .cobweb-blog {
      margin: 0;
    }

    .pentagon-image {
      height: 40%; 
      width: 40%;
    }

    .select-suit-content {
      height: 300px; 
    }
}
</style>
  
