<template>
  <div
    id="withdrawPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="withdrawPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Withdraw
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
                
        <form @submit.prevent="withdraw">
          <div class="modal-body">
            <div
              class="row"
              style="height: 70px; margin-bottom: 10px;"
            >
              <div class="col-3 item-center">
                In Game :
              </div>
              <div
                class="col-9 item-center"
                style="height: 100%; display: grid; grid-auto-rows: auto; padding: 0 1rem 0 0;"
              >
                <div
                  class="no-space-around"
                  style="border: 1px solid #495057; border-radius: 5px; width: 100%; padding: 0.5rem 0.75rem; display: flex; align-items: center; gap: 15px;"
                >
                  <div
                    class=""
                    style="width: auto;"
                  >
                    Balance : {{ inGameWallet.jabBalance }} JAB
                  </div>
                  <div
                    class="col-1 no-space-around"
                    style="display: flex; align-items: center;"
                  >
                    <img
                      data-v-1292d68d=""
                      src="~@/assets/icons/icon-jab.png"
                      alt="Owner Icon"
                      class="stat-icon"
                      height="20px"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div 
              class="row"
              style="margin-bottom: 10px;">
              <div
                class="col-3"
                style="display: flex; align-items: center;"
              >
                <label
                  for="eventTotal"
                  class="form-label no-space-around"
                >
                  <span> Withdraw : </span>
                </label>
              </div>
              <div
                class="col-9"
                style="padding: 0 1rem 0 0;"
              >
                <div class="">
                  <input
                    id="withdrawAmount"
                    v-model="withdrawAmount"
                    type="number"
                    min="0"
                    class="form-control"
                    placeholder="Enter Amount"
                    required
                  >
                </div>
                <div
                  v-if="showErrorAlert"
                  class="alert alert-danger mt-3"
                >
                  Invalid Total format.
                </div>
              </div>
            </div>
            <div 
              class="row">
              <div
                class="col-3"
                style="display: flex; align-items: center;"
              >
                <label
                  for="eventTotal"
                  class="form-label no-space-around"
                >
                  <span> Fee : </span>
                </label>
              </div>
              <div
                class="col-9"
                style="padding: 0 1rem 0 0;"
              >
                <div class="">
                  <div
                    class="">
                    <img
                      :src="require(`@/assets/currency/KRO.png`)"
                      alt="currency icon"
                      style="width: 20px; height: auto; margin-right: 10px"
                    >
                    10 $KRO
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
              style="min-width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else> Withdraw </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <TransactionPendingPopup :message="message" />
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref,computed } from 'vue';
import {useToast} from 'vue-toast-notification';
import PortalContractABI from '@/contracts/PortalContractABI.json';
import { getSignature } from '@/utils/web3Utils';

import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";

export default {
    components: {
        TransactionPendingPopup
    },
    props: {
        showModal: Boolean,
        inGameWallet: {
            type: Object,
            required: true
        }
    },
    setup() {
        const store = useStore()
    },
    data() {
        return {
            isLoading: false,
            showErrorAlert: false,
            withdrawAmount: 0,
            message: {}
        };
    },
    computed: {
        jabBalance: () => useStore().wallet.jab.toString(),
    },
    methods: {
        closeModal() {
            console.log('Closing modal'); // Add this line
            this.$refs.Close.click();
        },
        async withdraw() {
            var $ = jQuery.noConflict();

            this.isLoading = true;
            this.closeModal();
            this.message = {
                    title: 'Withdraw pending',
                    image: '../images/loading/transaction-loading.gif',
                    text: "Go to your wallet <br> You'll be asked to approve this transfer from your wallet."
                }
            $(document).ready(function() {
                $('#transactionPendingPopup').modal('show');
            });

            try {

              const { signature, challengeMessage, address } = await getSignature();
                const accountAddress = useStore().getWalletAddress;
                console.log("accountAddress" + accountAddress);

                /*const requestBody = {
                    amount: this.withdrawAmount.toString(),
                    token: "JAB",
                    signature,
                    challengeMessage,
                    address,
                    wallet_address: address
                };
                console.log('requestBody:', requestBody);*/


                //const apiUrl = `https://reward-portal.kryptofighters.io/api/protect/testnet/withdraw-token`;
                //console.log('apiUrl:', apiUrl);

                //const response = await axios.post(apiUrl, requestBody);
                //console.log('response:', response.data);
              
                const provider = new ethers.BrowserProvider(window.ethereum);
                const signer = await provider.getSigner(); // Use this if you have a signer (e.g., MetaMask)

                // Create an ethers.js contract instance
                const contract = new ethers.Contract(contractAddress, PortalContractABI, signer);

                const withdrawAmountInEther = ethers.utils.parseEther(this.withdrawAmount.toString());
                const tx = await contract.withdraw( withdrawAmountInEther);
                this.message.text = "Your transaction is pending. please do not close this page.";
                await tx.wait();

                useToast().open({
                        message: 'Withdraw completed.',
                        position: 'top-right',
                        type: 'success',
                    });

                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                
            } catch (error) {
                console.error('Error withdraw:', error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.closeModal();
            }
        }
    }
};
</script>

<style scoped>
.no-space-around {
    padding: 0;
    margin: 0;
}

.item-center {
    align-self: center;
}
</style>
  
