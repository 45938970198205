<template>
  <div class="page-content content-wrap" :style="{ display: isLoading ? 'none' : 'block' }">
    <div class="main-content" >
      <!-- <div style="background-color: #f8d7da; color: #721c24; padding: 20px; border: 1px solid #f5c6cb; border-radius: 5px; font-family: Arial, sans-serif;">
        <p style="margin: 10px 0; font-weight: bold;">Attention</p>
        <p style="margin: 10px 0;">
          The buy and sell functions are temporarily unavailable due to Rarible API maintenance. We apologize for any inconvenience caused.
        </p>
        <p style="margin: 10px 0; font-weight: bold;">Thank you for your patience—we'll be back soon!</p>
      </div> -->
      <h1>Marketplace</h1>
      <div class="row" style="margin-top: 20px;">
        <div class="col-md-2" style="max-width: 200px;">
          <router-link :to="{ name: 'marketplace_fighters', query: { method: 'recent_list' } }" class="link">
            <div class="card " style="position: relative; cursor: pointer; background-color: none; ">
              
              <div class="card-bg-image">
                <img :src="imageUrls['fighters-menu']" @load="onImageLoad" @error="onImageError" alt="Background Image">
                <div class="vertical-opacity-overlay" style="height: 100%;" />
              </div>
              <div class="card-body" style="position: relative; z-index: 1;">
                <h5 class="card-title text-white mt-2">Fighters</h5>
                <div v-if="totalFighter > 0">
                  <h5 class="stat-sub-title">{{ totalFighter }} Fighters</h5>
                </div>
                <div v-else>
                  <v-skeleton-loader :type="'text'" :animation="true" :width="100" :height="16" theme="dark" class="v-skeleton-loader-text" />
                </div>
              </div>
              
            </div>
          </router-link>
        </div>

        <div class="col-md-2" style="max-width: 200px;">
          <router-link :to="{ name: 'marketplace_tickets' }" class="link">
            <div class="card " style="position: relative; cursor: pointer; background-color: none; ">
              <div class="card-bg-image">
                <img :src="imageUrls['tickets-menu']" @load="onImageLoad" @error="onImageError" alt="Background Image">
                <div class="vertical-opacity-overlay" style="height: 100%;" />
              </div>
              <div class="card-body" style="position: relative; z-index: 1;">
                <h5 class="card-title text-white mt-2">Tickets</h5>
                <div v-if="totalTickets > 0">
                  <h5 class="stat-sub-title">{{ totalTickets }} Tickets</h5>
                </div>
                <div v-else>
                  <v-skeleton-loader :type="'text'" :animation="true" :width="100" :height="16" theme="dark" class="v-skeleton-loader-text" />
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div class="col-md-2" style="max-width: 200px;">
          <router-link :to="{ name: 'marketplace_artifacts', query: { method: 'recent_list' } }" class="link">
            <div class="card " style="position: relative; cursor: pointer; background-color: none; ">
              <div class="card-bg-image">
                <img :src="imageUrls['artifacts-menu']" @load="onImageLoad" @error="onImageError" alt="Background Image">
                <div class="vertical-opacity-overlay" style="height: 100%;" />
              </div>
              <div class="card-body" style="position: relative; z-index: 1;">
                <h5 class="card-title text-white mt-2">Artifacts</h5>
                <h5 class="stat-sub-title">-</h5>
              </div>
            </div>
          </router-link>
        </div>

        <div class="col-md-2" style="max-width: 200px;">
          <router-link :to="{ name: 'marketplace_stones', query: { method: 'recent_list' } }" class="link">
            <div class="card " style="position: relative; cursor: pointer; background-color: none; ">
              <div class="card-bg-image">
                <img :src="imageUrls['stones-menu']" @load="onImageLoad" @error="onImageError" alt="Background Image">
                <div class="vertical-opacity-overlay" style="height: 100%;" />
              </div>
              <div class="card-body" style="position: relative; z-index: 1;">
                <h5 class="card-title text-white mt-2">Stones</h5>
                <h5 class="stat-sub-title">-</h5>
              </div>
            </div>
          </router-link>
        </div>

        <!-- <div class="col-md-2">
          <router-link :to="{ name: 'marketplace_items' }" class="link">
            <div class="card card-border" style="position: relative; cursor: pointer;">
              <div class="card-bg-image">
                <img :src="require('@/assets/backgrounds/menu-bg-items.png')" alt="Background Image">
                <div class="vertical-opacity-overlay" style="height: 100%;" />
              </div>
              <div class="card-body" style="position: relative; z-index: 1;">
                <h5 class="card-title text-white mt-2">Items</h5>
                <div v-if="nftFrameStats?.stats">
                  <h5 class="stat-sub-title">{{ nftItemStats.stats.total_supply }} Items</h5>
                </div>
                <div v-else>
                  <v-skeleton-loader :type="'text'" :animation="true" :width="100" :height="16" theme="dark" class="v-skeleton-loader-text" />
                </div>
              </div>
            </div>
          </router-link>
        </div> -->
      </div>

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Overall stats</h5>
          <div class="container" style="background-color: var(--root-background-color);border-radius: 5px; padding: 5px;padding-left: 20px;padding-top: 10px; margin-top: 10px;">
            <div class="row">
              <div class="col-md-2">
                <div class="stat-section stats-logo">
                  <!--<img :src="require('@/assets/Icon-stats-fighters.png')" alt="NFT Icon" class="stat-icon mr-3">!-->
                  <div class="stat-content">
                    <p class="stat-title">Total Fighters</p>
                    <div v-if="totalFighter > 0">
                      <h5 class="stat-value">{{ totalFighter }}</h5>
                    </div>
                    <div v-else>
                      <v-skeleton-loader :type="'text'" :animation="true" :width="100" theme="dark" class="v-skeleton-loader-text" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="stat-section stats-logo">
                 <!-- <img :src="require('@/assets/Icon-stats-owners.png')" alt="Owner Icon" class="stat-icon">!-->
                  <div class="stat-content">
                    <p class="stat-title">Total Owners</p>
                    <div v-if="nftOwner">
                      <h5 class="stat-value">{{ nftOwner  }}</h5>
                    </div>
                    <div v-else>
                      <v-skeleton-loader :type="'text'" :animation="true" :width="100" theme="dark" class="v-skeleton-loader-text" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="stat-section stats-logo">
                 <!-- <img :src="require('@/assets/Icon-stats-volume.png')" alt="Volume Icon" class="stat-icon">!-->
                  <div class="stat-content">
                    <p class="stat-title">Total Volume</p>
                    <div v-if="totalVolume > 0" style="display: inline-flex;">
                      <img :src="require('@/assets/currency/KRO.png')" alt="Matic Icon" style="height: 16px; width: auto; margin: 0; margin-top: 3px;">
                      <h5 class="stat-value with-matic-icon">{{ totalVolume }}</h5>
                    </div>
                    <div v-else>
                      <v-skeleton-loader :type="'text'" :animation="true" :width="100" theme="dark" class="v-skeleton-loader-text" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="stat-section stats-logo">
                 <!-- <img :src="require('@/assets/Icon-stats-volume.png')" alt="Volume Icon" class="stat-icon">!-->
                  <div class="stat-content">
                    <p class="stat-title">Floor Price</p>
                    <div v-if="nftFloorPrice > 0" style="display: inline-flex;">
                      <img :src="require('@/assets/currency/KRO.png')" alt="Matic Icon" style="height: 16px; width: auto; margin: 0; margin-top: 3px;">
                      <h5 class="stat-value with-matic-icon">{{ nftFloorPrice }}</h5>
                    </div>
                    <div v-else>
                      <v-skeleton-loader :type="'text'" :animation="true" :width="100" theme="dark" class="v-skeleton-loader-text" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="stat-section stats-logo">
                 <!-- <img :src="require('@/assets/Icon-stats-volume.png')" alt="Volume Icon" class="stat-icon">!-->
                  <div class="stat-content">
                    <p class="stat-title">Market Cap</p>
                    <div v-if="nftMarketCap > 0" style="display: inline-flex;">
                      <img :src="require('@/assets/currency/ETH.png')" alt="Matic Icon" style="height: 16px; width: auto; margin: 0; margin-top: 3px;">
                      <h5 class="stat-value with-matic-icon">{{ nftMarketCap }}</h5>
                    </div>
                    <div v-else>
                      <v-skeleton-loader :type="'text'" :animation="true" :width="100" theme="dark" class="v-skeleton-loader-text" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="stat-section stats-logo">
                  <!--<img :src="require('@/assets/Icon-stats-transaction.png')" alt="Transaction Icon" class="stat-icon">!-->
                  <div class="stat-content">
                    <p class="stat-title">Listed</p>
                    <div v-if="nftListed">
                      <h5 class="stat-value">{{ nftListed }}</h5>
                    </div>
                    <div v-else>
                      <v-skeleton-loader :type="'text'" :animation="true" :width="100" theme="dark" class="v-skeleton-loader-text" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RecentListing />
      <RecentSales />
    </div>
  </div>
  <div v-if="isLoading" class="loading-content" style="/*padding: 50px 0px;*/" >
      <div class="loading-bar"></div>
  </div>
</template>


<script>
import RecentListing from '@/components/RecentListing.vue';
import RecentSales from '@/components/RecentSales.vue';

import { ref, onMounted } from 'vue';
import axios from 'axios';

import Web3 from 'web3';
import NFTContractABI from '@/contracts/NFTContractABI.json';

export default {
  components: {
    RecentListing,
    RecentSales
  },
  setup() {
    const collection = ref({});
    const nftStats = ref({});
    const nftTransaction = ref({});
    const nftOwner = ref({});
    const nftMarketCap = ref({});
    const nftListed = ref({});
    const nftFloorPrice = ref({});
    const nftFrameStats = ref({});
    const nftTicketStats = ref({});
    const nftItemStats = ref({});
    const totalVolume = ref({});
    const totalFighter = ref({});
    const totalTickets = ref({});
    const totalStones = ref({});
    const totalArtifacts = ref({});

    async function getEthToMaticExchangeRate() {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum,matic-network&vs_currencies=usd');
        const ethToUsd = response.data.ethereum.usd;
        const maticToUsd = response.data['matic-network'].usd;

        const ethToMaticExchangeRate = ethToUsd / maticToUsd;
        return ethToMaticExchangeRate;
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
        return null;
      }
    }

    async function fetchTicketStats(){
        const web3 = new Web3("https://polygon-mainnet.infura.io/v3/851bc9b8af734a6a999810181589649a");
        const products = [];
        try{
            // const nftContract = new web3.eth.Contract(NFTContractABI, process.env.VITE_TICKET_CONTRACT_ADDRESS);

            // const totalSupply = await nftContract.methods.totalSupply().call();
            const collection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
            const apiUrl = `https://api.rarible.org/v0.1/data/collections/KROMA:${collection}/stats?currency=ETH`;
            const response = await axios.get(apiUrl, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': process.env.VITE_RARIBLE_API_KEY
                }
            });
            // console.log("ticket stats" , response);
            // console.log(`Total NFTs in the contract: ${response.listed}`);
            totalTickets.value = response.data.items;

        } catch (error) {
            console.error('Error fetching total supply:', error);
        }
    }

    async function fetchStoneStats(){
        const web3 = new Web3("https://polygon-mainnet.infura.io/v3/851bc9b8af734a6a999810181589649a");
        const products = [];
        try{
            // const nftContract = new web3.eth.Contract(NFTContractABI, process.env.VITE_TICKET_CONTRACT_ADDRESS);

            // const totalSupply = await nftContract.methods.totalSupply().call();
            const collection = process.env.VITE_STONE_CONTRACT_ADDRESS;
            const apiUrl = `https://api.rarible.org/v0.1/data/collections/KROMA:${collection}/stats?currency=ETH`;
            const response = await axios.get(apiUrl, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': process.env.VITE_RARIBLE_API_KEY
                }
            });
            // console.log("ticket stats" , response);
            // console.log(`Total NFTs in the contract: ${response.listed}`);
            totalStones.value = response.data.items;

        } catch (error) {
            console.error('Error fetching total supply:', error);
        }
    }

    async function fetchArtifactStats(){
        const web3 = new Web3("https://polygon-mainnet.infura.io/v3/851bc9b8af734a6a999810181589649a");
        const products = [];
        try{
            // const nftContract = new web3.eth.Contract(NFTContractABI, process.env.VITE_TICKET_CONTRACT_ADDRESS);

            // const totalSupply = await nftContract.methods.totalSupply().call();
            const collection = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
            const apiUrl = `https://api.rarible.org/v0.1/data/collections/KROMA:${collection}/stats?currency=ETH`;
            const response = await axios.get(apiUrl, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': process.env.VITE_RARIBLE_API_KEY
                }
            });
            // console.log("ticket stats" , response);
            // console.log(`Total NFTs in the contract: ${response.listed}`);
            totalArtifacts.value = response.data.items;

        } catch (error) {
            console.error('Error fetching total supply:', error);
        }
    }

    const fetchItemsStats = () => {
      const apiKey = process.env.VITE_OPENSEA_API_KEY;
      const nftStatsUrl = 'https://api.opensea.io/api/v1/collection/kf-fighter-fragment/stats';

     
      axios.get(nftStatsUrl, {
        headers: {
          'X-API-KEY': apiKey,
          'Accept': 'application/json'
        }
      })
        .then(response => {
          console.log('NFT Item Stats API Response:', response.data);
          nftItemStats.value = response.data;
        })
        .catch(error => {
          console.error('Error fetching NFT stats data:', error);
        });
    };

    const fetchFramesStats = () => {
      const apiKey = process.env.VITE_OPENSEA_API_KEY;
      const nftStatsUrl = 'https://api.opensea.io/api/v1/collection/krypto-fighters-hall-of-frames/stats';

     
      axios.get(nftStatsUrl, {
        headers: {
          'X-API-KEY': apiKey,
          'Accept': 'application/json'
        }
      })
        .then(response => {
          nftFrameStats.value = response.data;
        })
        .catch(error => {
          console.error('Error fetching NFT stats data:', error);
        });
    };

    async function fetchFighterStats(){

      const web3 = new Web3("https://polygon-mainnet.infura.io/v3/851bc9b8af734a6a999810181589649a");
      const products = [];
      try{

          // const nftContract = new web3.eth.Contract(NFTContractABI, process.env.VITE_FIGHTER_CONTRACT_ADDRESS);
          // const totalSupply = await nftContract.methods.totalSupply().call();

          //const nftContract_genx = new web3.eth.Contract(NFTContractABI, process.env.VITE_FIGHTER_GENX_CONTRACT_ADDRESS);
          //const totalSupply_genx = await nftContract_genx.methods.totalSupply().call();

          const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
          const apiUrl = `https://api.rarible.org/v0.1/data/collections/KROMA:${collection}/stats?currency=ETH`;
          const response = await axios.get(apiUrl, {
              headers: {
                  'accept': 'application/json',
                  'x-api-key': process.env.VITE_RARIBLE_API_KEY
              }
          });
          // console.log("ticket stats" , response);

          // console.log(`Total NFTs in the contract: ${totalSupply}`);
          //console.log(`Total NFTs in the contract 2: ${totalSupply_genx}`);
          totalFighter.value = response.data.items;
          nftOwner.value = response.data.owners;
          // nftFloorPrice.value = response.data.floorPrice;
          // nftListed.value = response.data.listed;
          nftMarketCap.value = response.data.marketCap;
          const statResponse = await axios.get(`${process.env.VITE_KF_PORTAL_BASE_URL}/collection/stats?contractAddress=${collection}`);
          console.log('statResponse ',statResponse);
          nftFloorPrice.value = statResponse?.data?.floorPrice?? 0;
          nftListed.value = statResponse?.data?.totalListings?? 0;
          totalVolume.value = statResponse?.data?.totalVolTrade?? 0;
      } catch (error) {
          console.error('Error fetching total supply:', error);
      }
    }

    async function fetchData(){
      try {
        // const ticketCollection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
        // const ticketApiUrl = `https://testnet-api.rarible.org/v0.1/data/collections/KROMA:${ticketCollection}/transactions`;
        // const ticketResponse = await axios.get(ticketApiUrl, {
        //     headers: {
        //         'accept': 'application/json',
        //         'x-api-key': process.env.VITE_RARIBLE_API_KEY
        //     }
        // });
        // console.log("TicketResponse tx",ticketResponse);

        const fighterCollection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        const fighterApiUrl = `https://api.rarible.org/v0.1/data/collections/KROMA:${fighterCollection}/transactions`;
        const fighterResponse = await axios.get(fighterApiUrl, {
            headers: {
                'accept': 'application/json',
                'x-api-key': process.env.VITE_RARIBLE_API_KEY
            }
        });
        console.log("fighterResponse tx",fighterResponse);

        /*const combinedData = [...fighterResponse.data.historicalValues];
        console.log("combinedData ",combinedData);
        const totalValue = combinedData.reduce((accumulator, currentObject) => {
            const numericValue = Number(currentObject); // Convert to number
            // console.log("numericValue ",numericValue);
            return accumulator + (isNaN(numericValue) ? 0 : numericValue); // Handle NaN
        }, 0);*/

        nftTransaction.value = fighterResponse.data.currentValue;
      }
      catch (error) {
        
      }
      // const apiKey = process.env.VITE_OPENSEA_API_KEY;
      // const apiUrl = 'https://api.opensea.io/api/v2/collections/kf-genesis-fighter';
      // const nftStatsUrl = 'https://api.opensea.io/api/v2/collections/kf-genesis-fighter/stats';

      // // Fetch collection data
      // axios.get(apiUrl, {
      //   headers: {
      //     'Accept': 'application/json',
      //     'x-api-key': apiKey
      //   }
      // })
      //   .then(response => {
      //     collection.value = response.data;
      //     console.log('Collection API Response:', collection.value);
      //   })
      //   .catch(error => {
      //     console.error('Error fetching collection data:', error);
      //   });

      // // Fetch nftStats data
      // axios.get(nftStatsUrl, {
      //   headers: {
      //     'X-API-KEY': apiKey,
      //     'Accept': 'application/json'
      //   }
      // })
      //   .then(response => {
      //     nftStats.value = response.data;
      //     getEthToMaticExchangeRate().then(rate => {
      //       if (rate !== null) {
      //         totalVolume.value = Math.floor(nftStats.value.total.volume * rate);
      //         console.log(`ETH to MATIC Exchange Rate: 1 ETH = ${rate} MATIC`);
      //         console.log(`totalVolume = ${totalVolume.value} MATIC`);
      //       } else {
      //         console.log('Failed to fetch exchange rate.');
      //       }
      //     });
      //     console.log('NFT Stats API Response:', nftStats.value);
      //   })
      //   .catch(error => {
      //     console.error('Error fetching NFT stats data:', error);
      //   });
      
    }

    onMounted(() => {
      window.scrollTo(0, 0);
      fetchData(); // Fetch data when the component is mounted
      fetchFramesStats();
      fetchTicketStats();
      //fetchItemsStats();
      fetchFighterStats();
      fetchStoneStats();
      fetchArtifactStats();
    });

    return {
      collection,
      nftStats,
      nftTransaction,
      nftMarketCap,
      nftListed,
      nftFloorPrice,
      nftOwner,
      nftFrameStats,
      nftTicketStats,
      nftItemStats,
      totalVolume,
      fetchData,
      totalFighter,
      totalTickets,
      totalStones,
      totalArtifacts
    };
  },
  data() {
      return {
          isLoading: true,
          imageUrls: {'fighters-menu': require('@/assets/backgrounds/menu-bg-fighters.png'),
                      'tickets-menu': require('@/assets/backgrounds/menu-bg-tickets.png'),
                      'artifacts-menu': require('@/assets/backgrounds/menu-bg-artifact.png'),
                      'stones-menu': require('@/assets/backgrounds/menu-bg-stone.png'),},
          totalImages: 0,
          loadedImages: 0
      };
  },
  async mounted() {
      
      this.totalImages = Object.keys(this.imageUrls).length;
      this.loadedImages = 0;
  },
  methods: {
      onImageLoad() {
          this.loadedImages += 1;
          this.checkAllImagesLoaded();
      },
      onImageError() {
          this.loadedImages += 1;
          this.checkAllImagesLoaded();
      },
      async checkAllImagesLoaded() {
          console.log(`loaded image: ${this.loadedImages}/${this.totalImages}`);
          if (this.loadedImages === this.totalImages) {
              await new Promise(resolve => setTimeout(resolve, 1000));
              this.isLoading = false;
          }
      }
  }
}
</script>


<style scoped>
/* Add your component-specific styles here */
.nft-item {
  display: flex;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.nft-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.nft-details {
  flex: 1;
}

.card {
  
  padding: 8px;
  margin-bottom: 40px;
}

.card-border {
  border: 0px solid var(--light-background-color);
}

.card-border:hover {
  border: 1px solid var(--primary-background-color);
}

.with-matic-icon {
  background-image: url('@/assets/icon-stats-kroma.png');
  background-size: 20px 20px;
  /* Set the size of your icon */
  background-repeat: no-repeat;
  margin-left: 5px;  /* Adjust the padding to make space for the icon */
}

.stat-sub-title {
    margin-bottom: 0px; /* Adds space below .stat-title */
    font-size: 14px;
    color: var(--secondary-text-color);
  }
  
  .card-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden; 
    border-radius: 10px;
    background-color: rgb(27, 25, 35);
}

.card-bg-image img {
    opacity: 1.0; 
    height: 100%;
    
    transition: opacity 0.3s ease;
}

.loading-bar {
    width: 100%;
    height: 2px;
    background-color: var(--primary-background-color);
    position: relative;
    overflow: hidden;
    border-radius: 0px; 
}

.loading-bar:before {
    content: "";   
    position: absolute;
    top: 0;
    left: -100%;             
    width: 100%;            
    height: 100%;           
    background-image: linear-gradient(100deg, #df4bd3 50%, #8322ce 100%);
    animation: loading 2s linear infinite;
}

.loading-content {
    min-height: 650px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    z-index: 1;
    /* top: -650px; */
    background-color: #100f17;
}

/* Animation definition */
@keyframes loading {
  0% {
    left: -100%;            /* Start off-screen to the left */
  }
  100% {
    left: 100%;             /* Move fully to the right */
  }
}

@media (max-width: 768px) {
  .col-md-2 {
    width: 50%;
  }

  .col-md-2 .card {
    margin-bottom: 20px;
  }

  .col-md-3 {
    padding: 0;
    width: 50%;
  }

  .stat-content {
    overflow: hidden;
  }


  .cv-skeleton-loader-text {
    overflow: hidden;
  }
}

</style>