<template>
  <div class="product-sales-list">
    <product-shelves
      title="Recent Sales"
      :products="recentProducts"
    />
  </div>
</template>
  
  <script>
  import ProductShelves from '@/components/ProductShelves.vue';
  import axios from 'axios';
  import { timeAgo } from '@/utils/timeUtils.js';
  import { convertToUSD, findCurrencyByContract } from '@/utils/exchangeUtils.js';
  import {shortenedWalletAddress_rarible} from '@/utils/walletUtils.js';
  import { useExchangeStore } from '@/stores/exchangeRate.ts';

  export default {
    components: {
      ProductShelves
    },
    data() {
      return {
        recentProducts: []
      };
    },
    
    async mounted() {
      try {
        const store = useExchangeStore();
        await store.fetchExchangeRates();

        const wallet = store.getWalletAddress;
        
        const ethToUsdExchangeRate = store.getEthToUsdExchangeRate;
        const maticToUsdExchangeRate = store.getKroToUsdExchangeRate;

        // const response = await axios.get('https://api.opensea.io/api/v2/events/collection/kf-genesis-fighter?event_type=sale&limit=10', {
        //   headers: {
        //     'accept': 'application/json',
        //     'x-api-key': process.env.VITE_OPENSEA_API_KEY
        //   }
        // });

        const fighterCollection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        // const fighterApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byCollection?type=SELL&collection=KROMA:${fighterCollection}&size=16`;
        // const fighterResponse = await axios.get(fighterApiUrl, {
        //     headers: {
        //         'accept': 'application/json',
        //         'x-api-key': process.env.VITE_RARIBLE_API_KEY
        //     }
        // });
        // console.log("response recentSales" , fighterResponse);

        const ticketCollection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
        // const ticketApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byCollection?type=SELL&collection=KROMA:${ticketCollection}&size=8`;
        // const ticketResponse = await axios.get(ticketApiUrl, {
        //     headers: {
        //         'accept': 'application/json',
        //         'x-api-key': process.env.VITE_RARIBLE_API_KEY
        //     }
        // });
        // console.log("response recentSales" , ticketResponse);

        const stoneCollection = process.env.VITE_STONE_CONTRACT_ADDRESS;
        const artifactCollection = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
        
        // const combinedData = [...fighterResponse.data.activities, ...ticketResponse.data.activities];
        await new Promise(resolve => setTimeout(resolve, 2000));
        // const apiUrl = `https://api.rarible.org/v0.1/activities/byCollection?type=SELL&collection=KROMA:${fighterCollection}&collection=KROMA:${ticketCollection}&collection=KROMA:${stoneCollection}&collection=KROMA:${artifactCollection}&size=16`;
        // const response = await axios.get(apiUrl, {
        //     headers: {
        //         'accept': 'application/json',
        //         'x-api-key': process.env.VITE_RARIBLE_API_KEY
        //     }
        // });
        // console.log("response recentSales" , response);

        const fighterResponse = await axios.get(`https://reward-portal.kryptofighters.io/api/marketplace/collection/activities?contractAddress=${fighterCollection}&type=purchase&size=16`);
        console.log("fighterResponse recentSales" , fighterResponse);

        /*const ticketResponse = await axios.get(`https://reward-portal.kryptofighters.io/api/marketplace/collection/activities?contractAddress=${ticketCollection}&type=purchase&size=16`);
        console.log("ticketResponse recentSales" , ticketResponse);

        const stoneResponse = await axios.get(`https://reward-portal.kryptofighters.io/api/marketplace/collection/activities?contractAddress=${stoneCollection}&type=purchase&size=16`);
        console.log("stoneResponse recentSales" , stoneResponse);

        const artifactResponse = await axios.get(`https://reward-portal.kryptofighters.io/api/marketplace/collection/activities?contractAddress=${artifactCollection}&type=purchase&size=16`);
        console.log("artifactResponse recentSales" , artifactResponse);*/ 

        const combinedData = [...fighterResponse.data];

        combinedData.sort((a, b) => {  
          const date1 = new Date(b.timestamp);
          const date2 = new Date(a.timestamp);
          return date1 - date2;
        });

        console.log("response combinedData" , combinedData);

        // Extract relevant data from the API response and update recentProducts
        this.recentProducts = combinedData.map(listing => {
          const tokenID = listing.tokenId;
          const date = new Date(listing.timestamp);
          const unixTimestamp = Math.floor(date.getTime() / 1000);
          const dateString = timeAgo(unixTimestamp);
          const price = parseFloat(listing.price);
          const currency = findCurrencyByContract(listing.currencyAddress?? '').currency;
          const saleDate = ""; // Use the timeAgo function to format the sale date
          const usdPrice = convertToUSD(price, currency, ethToUsdExchangeRate, maticToUsdExchangeRate);
          const collection = listing.nftContract.toLowerCase();
          const isFighterProduct = (collection == fighterCollection.toLowerCase());

          var itemID = 0;

          if(!isFighterProduct){
            switch(collection){
              case process.env.VITE_TICKET_CONTRACT_ADDRESS.toLowerCase(): itemID = 100; break;
              case process.env.VITE_STONE_CONTRACT_ADDRESS.toLowerCase():
              case process.env.VITE_ARTIFACT_CONTRACT_ADDRESS.toLowerCase(): itemID = tokenID; break;
            }
          }
  
          return {
            id: tokenID,
            price: (currency == 'ETH')? price.toFixed(3):price,
            currency: currency,
            saleDate: saleDate,
            dateString: dateString,
            usdPrice: usdPrice.toFixed(2),
            isOwner: false,
            isFighter: isFighterProduct,
            itemId: itemID,
            image: '',
            type: '',
            description: '',
            isOwner: listing.seller == wallet
          };
        });

        // this.recentProducts = this.recentProducts.slice(0, 15);

       /* for (const data of this.recentProducts) {
          if(data.isFighter == false){
            try{
              const apiUrl = `https://testnet-api.rarible.org/v0.1/items/KROMA:${ticketCollection}:${data.id}`;

              const headers = {
                'accept': 'application/json',
                'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
              };

              
              const nftResponse = await axios.get(apiUrl, {headers}  );
              console.log("nftResponse:", nftResponse);

              data.image = nftResponse.data.meta.content[0].url;
              data.type = nftResponse.data.meta.name;
              data.description = nftResponse.data.meta.description;
            }
            catch (error) {
                console.error(`Error fetching data for token ID ${data.id}:`, error);
            }
          }
        }*/
        console.log("this.recentProducts", this.recentProducts);
      } catch (error) {
        console.error('Error fetching data :', error);
      }
    }
  };
  </script>
  
  <style scoped>
  /* Styles for the ParentComponent */
  </style>
  