<template>
  <div class="product-item">
    <div
      v-if="isLoading"
      style="margin-bottom: 50px;"
    >
      <v-skeleton-loader
        :type="'card'"
        :animation="true"
        :width="200"
        :height="300"
        theme="dark"
      />
    </div>
    <div v-else style="width: 100%;">
      <router-link
        :to="product.isOwner ? { name: 'inventory_item', query: { id: product.id, itemId: product.itemId, owned: product.isOwner , amount: product.amount } } : { name: 'item', query: { id: product.id, itemId: product.itemId, owned: product.isOwner , amount: product.amount , order: product.orderHash } }"
        class="link"
      >
        <div class="card product-card-container">
          <div class="card-body">
            <div class="product-header">
              <div class="left-content" style="width: 100%;">
                <div class="product-id" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  #{{ product.id }} {{ product.type }}
                </div>
              </div>
            </div>
            <div class="image-container" style="margin-bottom: 20px">
                <img
                  :src="product.image"
                  alt="Product Image"
                  class="product-image"
                >
            </div>

            <div v-if="product.price !== 0">
              <div v-if="product.totalStock" style="margin-bottom: 30px;">
                <h4 class="product-usd-price">
                  {{ product.totalStock }} PCS
                </h4>
              </div>
              <div style="display: flex; justify-content: center; align-items: center; gap: 5px;">
                <img
                  :src="require(`@/assets/currency/${product.currency}.png`)"
                  alt="JAB"
                  style="width: 20px; height: auto;"
                >
                <h4 class="product-price" style="margin: 0; text-align: center;">
                  {{ product.price.toFixed(2) }}
                </h4>
                
              <!-- <h4 class="product-usd-price" v-if="product.usdPrice !== 0">
                  $ {{ product.usdPrice.toFixed(2) }} 
                </h4>-->
              </div>  
            </div>
            <div v-else>
              <div v-if="product.amount">
                <h4 class="product-usd-price">
                  {{ product.amount }} PCS
                </h4>
              </div>
            </div>

            <div v-if="product.isListing == true" style="width: 100%; display: inline-flex; justify-content: center;">
              <div class="product-title" style="width: auto; color: aqua; font-weight: 500; padding-right: 15px; padding-left: 15px;">
                listed
              </div>
            </div>
          </div>
        </div>
      </router-link>
      <div class="product-time">
        {{ product.dateString }}
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { getClassImageUrl } from '@/utils/imageUtils.js';
// import LinkAccountPopup from '@/components/popup/NFTItemPopup.vue';
import NFTTicketPopup from '@/components/popup/NFTTicketPopup.vue';

export default {
    components: {
        NFTTicketPopup,
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        type: ""
    },
    data() {
        return {
            showModal: Boolean,
            isLoading: false
        }
    },
    mounted() {
      // console.log('mounted', this.product);
        this.fetchNftData();
    },
    methods: {
        async fetchNftData() {
          this.isLoading = true;
            if (this.product.itemId == 10) {
                //Hall of Frame
                this.isLoading = true;
                const apiUrl = `https://api.opensea.io/api/v2/chain/matic/contract/0xe3934ea095a0fa3971a692b3355808c7fe5a406c/nfts/${this.product.id}`;
                const headers = {
                    'accept': 'application/json',
                    'x-api-key': process.env.VITE_OPENSEA_API_KEY,
                };

                const response = await axios.get(apiUrl, { headers });

                let effects = [];
                let rarity = null;

                // Loop through the traits array to find the "Effect" and "Rarity"
                response.data.nft.traits.forEach((trait) => {
                    if (trait.trait_type === 'Rarity') {
                        rarity = trait.value;
                    } else {
                        effects.push(trait.trait_type);
                    }
                });
                console.log(effects);
                this.product.image = response.data.nft.image_url;
                this.product.name = response.data.nft.name;
                /*this.item = {
                            token_id: this.tokenId,
                            description: 'A Border/Frame for your in-game user profile picture in the form of NFT which allows you to collect different rarity tiers and unique designs each tier having its own in-game benefits apart from the collectible value.',
                            image: response.data.nft.image_url,
                            name: response.data.nft.name,
                            effects: effects,
                            rarity: rarity
                        };*/

            }else if(this.product.itemId == 4 || this.product.itemId == 1|| this.product.itemId == 2 || this.product.itemId == 3){
                //Fragment and Lootbox
                const item = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fragment/${this.product.itemId}.json`);
                console.log(item);
                
                this.product.image = item.data.image;
                this.product.name = item.data.name;
                this.product.type = item.data.name;
            }
            else if(this.product.itemId == 100){
              const collection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
              const ticketCollection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
              try{
                const apiUrl = `https://api.rarible.org/v0.1/items/KROMA:${ticketCollection}:${this.product.id}`;

                const headers = {
                  'accept': 'application/json',
                  'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                };

                const nftResponse = await axios.get(apiUrl, {headers}  );
                console.log("nftResponse:", nftResponse);

                this.product.image = nftResponse.data.meta.content[0].url;
                this.product.type = nftResponse.data.meta.name;
                this.product.description = nftResponse.data.meta.description;
              }
              catch (error) {
                  console.error(`Error fetching data for token ID ${this.product.id}:`, error);
              }
            }
            else if(this.product.itemId >= 10000){
              const filterType = (this.product.itemId >= 20000)? 'artifact':'stone';
              try{
                const response = await axios.get(`https://metadata.kryptofighters.io/item/${filterType}/${this.product.id}.json`);
                console.log("nftResponse:", response);

                this.product.image = response.data.image;
                this.product.type = response.data.name;
                this.product.description = response.data.description;
              }
              catch (error) {
                  console.error(`Error fetching data for token ID ${this.product.id}:`, error);
              }
            }

            const image = new Image();

            image.onload = () => {
                this.isLoading = false;
            };
            image.src = this.product.image ;
        }

    }
};
</script>
  
<style scoped>

.product-item {
    text-align: center;
    margin-bottom: 0px;
}

.product-image {
    width: 140px;
    object-fit: cover;
    margin-bottom: 10px;
    margin-top: 10px;
}

.product-name {
    font-size: 16px;
    text-align: center;
    align-items: center;
    /* Center items vertically */
    justify-content: space-between;
    width: 100%;
}

.product-card-container:hover {
  background: linear-gradient(180deg, rgba(13, 10, 22, 0.6), rgba(255, 255, 255, 0.13)); 
}

.product-card-container {
  max-width: 205px;
  background: linear-gradient(180deg, rgba(13, 10, 22, 0.8), rgba(255, 255, 255, 0.07)); 
}

.product-usd-price {
    margin-top: 0px;
    font-size: 14px;
    color: var(--secondary-text-color);
}

.product-time {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 14px;
    color: var(--secondary-text-color);
    max-width: 205px;
}

.product-title{
    background-color: var(--soft-background-color);
    padding: 2px 6px;
    border-radius: 5px;
}

.link {
    text-decoration: none;
}

.product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.with-class-icon {
    background-image: url('@/assets/icon-stats-kroma.png');
    background-size: 20px 20px;
    /* Set the size of your icon */
    background-repeat: no-repeat;
    padding-left: 30px;
    /* Adjust the padding to make space for the icon */
}



.icon-element-right {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    /* Adjust margin to create space between the two icons */
}

.left-content {
    display: flex;
    align-items: center;
}

.link {
    text-decoration: none;
}

.open-button {
    height: 20%;
    width: 100%;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    font-size: 16px;
    border: 2px solid #2980b9;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: background-color 0.3s, transform 0.3s;
}

.open-button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.clickable-image {
    cursor: pointer;
}

.card-body {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
    .product-image {
      width: 80px;
      margin-bottom: 0px;
      margin-top: 10px;
    }

    .product-card-container {
      width: 140px;
    }

    .card-body {
      height: 250px;
      padding: 0.5rem 0.5rem;
    }
}

</style>