<template>
  <div
    id="craftPopup"
    class="modal fade no-padding"
    tabindex="-1"
    aria-labelledby="craftPopup"
    aria-hidden="true"
    @click.self="selectButton"
  >
    <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width: 500px;">
      <div class="modal-content" style="background-color: #151030; border: 1px solid #7A37E6; border-radius: 8px;">
        <div class="modal-header">
          <div style="background: linear-gradient(20deg, rgb(248, 4, 156) 20%, rgb(74, 31, 187) 80%); height: 0.5em; width: 100%; border-radius: inherit;"></div>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="selectButton"
            style="margin: 0.25em 0.5em; padding: 0;"
          />
        </div>
        <div class="modal-body" style="padding: 0;">
          <div class="d-flex align-items-center justify-content-center" style="margin-top: -10px;">
            <img class="electric-bg" :src="require(`@/assets/workshop/electric-bg.png`)" alt="electric bg" style="height: 90px; position: relative;" />
            <div class="d-flex align-items-center justify-content-center" style="position: absolute; z-index: 1; flex-direction: column;">
              <img class="electric-bg" :src="require(`@/assets/workshop/craft-header.png`)" alt="electric bg" style="height: 40px;" />
              <div style="font-size: 18px; font-weight: 500;">
                Choose your desired outcome
              </div>
            </div>
          </div>
          <div style="padding: 0 20px 10px 20px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <div style="display: flex; justify-content: center; align-items: center; width: 100%; margin: 10px; overflow-x: auto; gap: 15px; flex-wrap: wrap;">
              <template v-for="recp in recipesList">
                <label
                  v-if="recp.id > 0"
                  :key="recp.id"
                  class="btn btn-secondary dropdown-filter-toggle recipe-button"
                  :class="{ active: isSelectedRecipe(recp.id) }"
                  style=""
                >
                  <input
                    type="radio"
                    class="btn-check"
                    v-model="selectedRecipe"
                    @change="selectRecipe(recp)"
                    style="display: none;"
                  />
                  <img width="30" :src="`../images/item/${recp.code}.png`" alt="item icon" style="padding-right: 5px;" />
                  <span class="content-text">{{ recp.name }}</span>
                </label>
              </template>
            </div>  
            <div class="d-flex justify-content-center align-items-center" style="margin: 0 30px; background-color: #101327; width: 100%; border-radius: 8px; flex-direction: column;">
              <!-- <div style="color: #B6B6BB; margin: 5px; font-weight: 500;">material</div> -->
              <div class="d-flex justify-content-center align-items-center" style="gap: 20px; margin: 10px 0 20px 0;">
                <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                  <img class="material-icon" :src="`../images/item/${getMaterialUse()?.code || ''}.png`" alt="material 1 icon" style="" />
                  <div class="detail-text width-detail-text">
                    <span id="amountId">{{ amount }}</span>&nbsp;/&nbsp;{{ materialUseTotal }}
                    <!-- <span id="amountId">{{ getMatetialAmount(getMaterialUse()?.code?? '') }}</span>&nbsp;/&nbsp;{{ materialUseTotal }} -->
                  </div>
                </div>
                <img width="20" :src="`../images/arrow-icon.png`" alt="icon" style="" />
                <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                  <img class="material-icon" :src="`../images/item/${selectedRecipe.code || ''}.png`" alt="material 1 icon" style="" />
                  <div id="resultAmountId" class="detail-text width-detail-text">
                    {{ craftAmount }}
                  </div>
                </div>
              </div>
              <div style="color: #FFD600; margin: 5px; font-weight: 500; padding: 10px; font-size: 18px;">Success rate {{ getMaterialUse()?.rate?? 0 }}%</div>
            </div>
            <div class="d-flex align-items-end" style="width: 90%; margin: 10px 0; height: auto; justify-content: center; background-color: #241D4F; border-radius: 8px; padding: 15px 30px;">
              Tip: If crafting fails, Meterial will break down into {{ getMaterialUse()?.name || '' }}.
            </div>
            <div class="d-flex align-items-end" style="width: 100%; margin: 10px 0; justify-content: center;">
              <!-- <div class="detail-text" style="background-color: black; width: 100px; height: 80%;"> total: </div> -->
              <div class="d-flex justify-content-center align-items-center" style="height: 100%; gap: 10px; flex-direction: column;">
                <div v-if="selectedRecipe.id == 1" class="d-flex justify-content-center align-items-center" style="height: 40px; gap: 10px;">
                  <div class="d-flex justify-content-center align-items-center" style="width: auto; height: 80%;">
                    <button 
                      :class="['amount-content', 'btn-decrease', { 'btn-disabled': isDecreaseDisable }]" 
                      :disabled="isDecreaseDisable" 
                      @click="updateCraftAmount(false)" 
                      style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;"> - 
                    </button>
                    <span class="amount-content d-flex justify-content-center align-items-center" style=""> {{ craftAmount }} </span>
                    <button 
                      :class="['amount-content', 'btn-increase', { 'btn-disabled': isIncreaseDisable }]" 
                      :disabled="isIncreaseDisable" 
                      @click="updateCraftAmount(true)" 
                      style="border-top-right-radius: 8px; border-bottom-right-radius: 8px;"> + 
                    </button>
                  </div>
                  <button class="detail-text" :class="['amount-content', 'btn-increase', { 'btn-disabled': isCraftDisable }]" :disabled="isCraftDisable" @click="getMaxCraft()" style="background-color: black; width: 50px; height: 80%; padding: 0 10px;"> max </button>
                </div>
                <button :class="['btn', 'btn-primary', { 'btn-disabled': isCraftDisable }]" :disabled="isCraftDisable" @click="craft" style="background-color: black; padding: 0 50px; height: 40px; font-size: 20px; font-weight: 500;"> 
                  <div v-if="isLoading">
                    <i class="fa fa-circle-o-notch fa-spin" />
                  </div>
                  <div v-else>
                    Craft
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
  <TransactionPendingPopup :message="message" />
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { getSignature } from '@/utils/web3Utils';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import {useToast} from 'vue-toast-notification';
import { ref, onMounted, watch } from 'vue';
import CraftABI from '@/contracts/CraftABI.json';
import { ethers } from 'ethers';

import {  sendMessage } from '@/utils/walletUtils';
import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";

function getEventLog(receipt, eventName, eventAbi) {
    if (!receipt || !receipt.logs) {
        console.log("Receipt or logs are missing.");
        return null;
    }

    const iface = new ethers.utils.Interface([eventAbi]);
    console.log(`Searching for event "${eventName}" in ${receipt.logs.length} logs...`);

    // Loop through logs and attempt to parse
    for (const log of receipt.logs) {
        console.log("Log topics:", log.topics);
        console.log("Log data:", log.data);

        try {
            const parsedLog = iface.parseLog(log);
            console.log(`Parsed log with address ${log.address} - Event: ${parsedLog.name}`);

            if (parsedLog.name === eventName) {
                console.log(`Event "${eventName}" found. Decoded arguments:`, parsedLog.args);
                return parsedLog.args;
            } else {
                console.log(`Skipping event "${parsedLog.name}", does not match "${eventName}".`);
            }
        } catch (error) {
            console.log(`Failed to parse log with address ${log.address}:`, error.message);
        }
    }

    console.log(`Event "${eventName}" not found in any log.`);
    return null;
}


export default {
    components: {
      TransactionPendingPopup
    },
    props: {
        showModal: Boolean,
        totalFragment: {
            type: Object,
            required: true
        }
        // selectedDefault: {
        //     type: Number,
        //     required: true,
        //     default: () => 0
        // }
    },
    data() {
        return {
          // totalFragment: [{code:'ITM_FM_MCC', amount:0},
          //                   {code:'ITM_FM_GLB', amount:0},
          //                   {code:'ITM_FM_HLB', amount:0},
          //                   {code:'ITM_FM_FST', amount:0}],
          recipesList: [{id:0, code:'ITM_FM_MCC', name:'Microship', material_use:5, rate:100}, 
                        {id:1, code:'ITM_FM_GLB', name:'Gold Bar', material_use:2, rate:80}, 
                        {id:2, code:'ITM_FM_HLB', name:'Holo Bar', material_use:2, rate:70}, 
                        {id:3, code:'ITM_FM_FST', name:'Suit'}],
          selectedRecipe: {},
          craftAmount: 1,
          materialUseTotal: 0,
          isDecreaseDisable: false,
          isIncreaseDisable: false,
          isCraftDisable: false,
          isLoading: false,
          isSuitCrafted: false,
          message: {}
        };
    },
    computed: {
      amount() {
          return this.getMatetialAmount(this.getMaterialUse()?.code ?? '');
      }
    },
    watch: {
      // selectedDefault: {
      //   handler(newVal, oldVal) {
      //     console.log('selectedDefault :', newVal);
      //     this.selectedRecipe = this.recipesList.find(f=>f.id == newVal);
      //   },
      //   immediate: true
      // }
      amount(newValue) {
          // console.log('Amount changed to:', newValue);
          this.updateButton();
      }
    },
    mounted() {
      // this.selectedRecipe = this.recipesList.find(f=>f.id == 1);
      // console.log(this.totalFragment);
      this.selectRecipe(this.recipesList.find(f=>f.id == 1));
      // if(useStore().getWalletAddress!=''){
      //       this.fetchData();
      //   }else{
      //       watch(() => useStore().getWalletAddress, () => {
      //           this.fetchData();
      //       });
      //   }
      this.updateButton();
    },
    methods: {
      closeModal(){
        console.log('closeModal');
        // this.$refs.Close.click();
      },
      async selectButton() {
          console.log('Closing modal'); // Add this line
          // this.$emit('selectButton', 0);
          // this.closeModal();
          await new Promise(resolve => setTimeout(resolve, 1000));
          this.$emit('refreshTotal', this.isSuitCrafted);
          this.isSuitCrafted = false;
      },
      isSelectedRecipe(id){
        // console.log(this.selectedRecipe);
        return this.selectedRecipe ? (this.selectedRecipe.id === id) : false;
      },
      selectRecipe(recp){
        this.selectedRecipe = recp;

        this.craftAmount = 1;
        this.materialUseTotal = this.getMaterialUse()?.material_use?? 0;
        this.updateButton();
      },
      updateCraftAmount(isIncrease){
        if (isIncrease) {
          this.craftAmount++; // Increment
        } else {
          this.craftAmount--; // Decrement
        }

        this.materialUseTotal = (this.getMaterialUse()?.material_use?? 0)*this.craftAmount;
        
        this.updateButton();
      },
      updateButton(){
        if(this.craftAmount == 1){
          this.isDecreaseDisable = true;
        }
        else{
          this.isDecreaseDisable = false;
        }

        const materialUse = this.getMaterialUse()?.material_use?? 0;
        const maxResult = Math.floor(this.getMatetialAmount(this.getMaterialUse()?.code?? '') / materialUse); 
        if(this.craftAmount == maxResult){
          this.isIncreaseDisable = true;
        }
        else{
          this.isIncreaseDisable = false;
        }

        const hasTotal = this.getMatetialAmount(this.getMaterialUse()?.code?? '');

        const amountIdElement = document.getElementById('amountId');
        if(hasTotal < this.materialUseTotal){
          this.isCraftDisable = true;
          this.isIncreaseDisable = true;
          this.isDecreaseDisable = true;
          amountIdElement.style.color = 'red';
        }
        else{
          this.isCraftDisable = false;
          amountIdElement.style.color = 'lightgreen';
        }
      },
      getMaterialUse(){
        return this.recipesList.find(f=>f.id == ((this.selectedRecipe?.id?? 0)-1));
      },
      getMatetialAmount(code){
        return this.totalFragment?.find(f=>f.code == code)?.amount?? 0;
      },
      getMaxCraft(){
        const materialUse = this.getMaterialUse()?.material_use?? 0;
        const maxResult = Math.floor(this.getMatetialAmount(this.getMaterialUse()?.code?? '') / materialUse); 

        this.craftAmount = maxResult;
        this.materialUseTotal = materialUse*this.craftAmount;
        this.updateButton();
      },
      async craft(){
        this.isSuitCrafted = false;
        this.isCraftDisable = true;
        this.isLoading = true;
        const hasTotal = this.getMatetialAmount(this.getMaterialUse()?.code?? '');

        if(hasTotal < (this.getMaterialUse()?.material_use?? 0)){
        // if(false){
          useToast().open({
                      message: `Error: Can not craft ${this.selectedRecipe.name}.`,
                      position: 'top-right',
                      type: 'error',
                  });
        }
        else{
          this.message = {
                title: `Crafting ${this.selectedRecipe.name}`,
                image: require('@/assets/images/crafting-loading.gif'),
                text: "Go to your wallet <br> You'll be asked to approve this transfer from your wallet."
                
            }
            
          $(document).ready(function() {
              $('#transactionPendingPopup').modal('show');
          });

          const audioForge = new Audio(require('@/assets/sound/SFX_Forge.wav'));
          // audioForge.loop = true;
          audioForge.play(); 

          var currenMaterialAmount = 0;
          const materialUse = this.getMaterialUse();
          if(materialUse){
            const material = this.totalFragment?.find(f => f.code === materialUse.code);
            if (material) {
              currenMaterialAmount = material.amount;
              material.amount -= (materialUse.material_use*this.craftAmount) || 0;
            }
          }

          try{
            const contractAddress = "0xd3BE2426A20BB50129B74fBDc27B1E2eEB092870";
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = await provider.getSigner();
            const craftContract = new ethers.Contract(contractAddress, CraftABI, signer);
            const web3 = new Web3(window.ethereum);
            const accounts = await web3.eth.getAccounts();
            // console.log(accounts);

            // var isSuccess = false;
            if(accounts){
              switch(this.selectedRecipe.id){
                case 1: {
                        sendMessage( useStore().getWalletAddress , "start crafting goldbar");
                        // const tx = await craftContract.craftGoldBar({ from: accounts[0] });
                        const tx = await craftContract.craftGoldBarBulk(this.craftAmount, { from: accounts[0] });
                        console.log('Crafted Gold Bar', tx);
                        this.message.text = "Please do not close this page.";

                        await tx.wait();

                        console.log(tx);
                        const receipt = await provider.getTransactionReceipt(tx.hash);
                        console.log(receipt);
                        if (receipt && receipt.logs) {

                        }
                        $(document).ready(function() {
                            $('#transactionPendingPopup').modal('hide');
                        });
                      }
                        break;
                case 2: {
                        sendMessage( useStore().getWalletAddress , "start crafting holobar");
                        const tx = await craftContract.craftHoloBar({ from: accounts[0] });
                        this.message.text = "Please do not close this page.";
                        console.log('Crafted Holo Bar', tx);

                        await tx.wait();

                        console.log(tx);
                        const receipt = await provider.getTransactionReceipt(tx.hash);

                        

                        if (receipt && receipt.logs) {

                          const eventName = 'HoloBarCrafted';
                          const eventAbi = "event HoloBarCrafted(address indexed user, bool success)";

                          console.log('All receipt logs:', receipt.logs);

                          const eventArgs = getEventLog(receipt, eventName, eventAbi);
                          console.log('eventArgs:', eventArgs);

                          $(document).ready(function() {
                              $('#transactionPendingPopup').modal('hide');
                          });

                          if (eventArgs) {
                              const success = eventArgs.success;
                              console.log(`Crafted HoloBar - Success: ${success}`);
                              if (success === false) {
                                  useToast().open({
                                      message: 'Oops! Crafting failed',
                                      position: 'top-right',
                                      type: 'error',
                                  });
                                  sendMessage( useStore().getWalletAddress , "Craft holobar fail!");
                                  this.isLoading = false;
                                  return;
                              }
                          } else {
                              console.log(`Crafted HoloBar - Event not found`);
                          }
                          console.log('Crafted HoloBar receipt:', receipt);
                        }
                        sendMessage( useStore().getWalletAddress , "Craft Holobar success!");

                        // const event2 = receipt2.logs.find(log => log.event === 'HoloBarCrafted');
                        // if (event2) {
                        //     const success = event2.args.success;
                        //     console.log(`Crafted HoloBar - Success: ${success}`);
                        //     isSuccess = true;
                        // } else {
                        //     console.log('Crafted HoloBar - Event not found');
                        // }
                      }
                        break;
                case 3: { 
                        sendMessage( useStore().getWalletAddress , "start crafting fighter suit");

                        const tx =  await craftContract.craftFighterSuit({ from: accounts[0] });
                        this.message.text = "Please do not close this page.";
                        console.log('Crafted Fighter Suit', tx);

                        await tx.wait();

                        console.log(tx);
                        const receipt = await provider.getTransactionReceipt(tx.hash);

                        if (receipt && receipt.logs) {

                          const eventName = 'FighterSuiteCrafted';
                          const eventAbi = "event FighterSuiteCrafted(address indexed user, bool success, uint256 nativeNFTId)";

                          console.log('All receipt logs:', receipt.logs);

                          const eventArgs = getEventLog(receipt, eventName, eventAbi);
                          console.log('eventArgs:', eventArgs);

                          $(document).ready(function() {
                              $('#transactionPendingPopup').modal('hide');
                          });

                          if (eventArgs) {
                              const success = eventArgs.success;
                              console.log(`Crafted Fighter Suit - Success: ${success}`);
                              if (success === false) {
                                  useToast().open({
                                      message: 'Oops! Crafting failed',
                                      position: 'top-right',
                                      type: 'error',
                                  });
                                  sendMessage( useStore().getWalletAddress , "Craft fighter suit fail!");
                                  this.isLoading = false;
                                  return;
                              }
                          } else {
                              console.log(`Crafted Fighter Suit - Event not found`);
                          }

                          console.log('Crafted Fighter Suit receipt:', receipt);

                        }

                        sendMessage( useStore().getWalletAddress , "Craft Fighter's Suit success!");
                        this.isSuitCrafted = true;
                        // const event3 = receipt3.logs.find(log => log.event === 'FighterSuiteCrafted');
                        // if (event3) {
                        //     const success = event3.args.success;
                        //     console.log(`Crafted Fighters Suit - Success: ${success}`);
                        //     isSuccess = true;
                        // } else {
                        //     console.log(`Crafted Fighters Suit - Event not found`);
                        // }
                      }
                        break;
              }

              // await new Promise(resolve => setTimeout(resolve, 5000));
                  
              // await this.fetchData();
              // if(isSuccess) {
                const craftedMaterial = this.totalFragment?.find(f => f.code === this.selectedRecipe.code);
                if (craftedMaterial) {
                  craftedMaterial.amount += this.craftAmount;
                }

                this.updateButton();
              // }
              const audioComplete = new Audio(require('@/assets/sound/SFX_QuestReward.mp3'));
              audioComplete.play(); 
              useToast().open({
                      message: `Craft ${this.selectedRecipe.name} completed.`,
                      position: 'top-right',
                      type: 'success',
                  });

              console.log(this.totalFragment);
            }
          } catch (error) {
              $(document).ready(function() {
                  $('#transactionPendingPopup').modal('hide');
              });
              console.error('Error deposit:', error);
              const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
              useToast().open({
                      message: 'Error: ' + errorMessage,
                      position: 'top-right',
                      type: 'error',
                  });
              
              alert('Please refresh your browser.');

              // const materialUse = this.getMaterialUse();
              // if(materialUse){
              //   const material = this.totalFragment?.find(f => f.code === materialUse.code);
              //   if (material) {
              //     material.amount = currenMaterialAmount;
              //   }
              // }
          }
        }
        this.isCraftDisable = false;
        this.isLoading = false;
      }
    }
};
</script>

<style scoped lang="scss">
  .no-padding {
    padding: 0 !important;
  }

  .modal-header {
    border-bottom: none;
    padding: 0;
    flex-direction: column;
    align-items: end;
    position: relative;
    z-index: 2;
  }

  .modal-title {
    text-align: center;
    width: 100%;
    font-weight: 500;
    color: #ff65fa;
    font-size: 28px;
    margin-bottom: 5px;
  }

  .dropdown-filter-toggle {
    background: rgb(60 53 90);
    transition: background 0.3s, border-color 0.3s, color 0.3s;
  }

  .dropdown-filter-toggle.active {
    background: linear-gradient(135deg, #df4bd3 20%, #a33af4 80%);
    // background-color: #ff65fc55;
    border-color: #ff65fa;
    color: white;
  }

  .detail-text {
    color: white;
    display: inline-flex;
    align-items: center;
    background-color: var(--light-background-color);
    border-radius: 8px;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    width: 60px;
    font-weight: 500;
  }

  .width-detail-text{
    width: 100px;
  }

  .material-icon {
    width: 100px;
  }

  .btn-decrease {
    background-color: black;
  }

  .btn-increase {
    background-color: black;
  }

  .btn-decrease:hover:not(.btn-disabled){
    background-color: darkred;
  }

  .btn-increase:hover:not(.btn-disabled) {
    background-color: darkgreen;
  }

  .btn-disabled{
    background-color: #e0e0e0; /* Grey */
    color: darkgray; /* Light grey */
    cursor: not-allowed;
    opacity: 0.6;
  }

  .amount-content {
    width: 40px; 
    height: 100%; 
    background-color: black;
    color: white;
  }

  .content-text {
    // width: 100%;
    text-align: center;
  }

  .recipe-button {
    padding: 10px; 
    cursor: pointer; 
    display: inline-flex; 
    align-items: center; 
    justify-content: center; 
    width: 25%;
  }

@media (max-width: 768px) {
    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }

    .material-icon {
      width: 70px;
    }

    .content-text {
      font-size: 14px;
    }

    .recipe-button {
      width: 30%;
      padding: 5px 10px; 
    }
}
</style>
  
