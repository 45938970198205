<template>
  <div
    id="linkAccountPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="linkAccountPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="row"
          style="height: 400px; margin: 0;"
        >
          <div
            class="col-4"
            style="padding: 0;"
          >
            <img
              src="~@/assets/banner.png"
              class="banner-image"
              alt="KF Banner"
            >
          </div>
          <div class="col-8">
            <div class="row header-row">
              <div class="modal-header">
                <h5
                  id="exampleModalLabel"
                  class="modal-title"
                >
                  Link Game Account
                </h5>
                <button
                  ref="Close"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="closeModal"
                />
              </div>
            </div>
            <div class="detail-block">
              <!-- <div class="row">
                                <form @submit.prevent="submitEmail">
                                    <div class="modal-body">
                                        <div class="">
                                            <label for="eventEmail" class="form-label">
                                                Email
                                            </label>
                                            <input v-model="eventEmail" type="text" class="form-control" id="eventEmail"
                                                placeholder="kryptofighter@gmail.com" required>
                                        </div>
                                        <div v-if="showErrorAlert" class="alert alert-danger mt-3">
                                            Invalid Email format.
                                        </div>
                                    </div>
                                </form>
                            </div> -->
              <div class="row">
                <form @submit.prevent="submitCode">
                  <div class="modal-body">
                    <div
                      class=""
                      style="margin-bottom: 1rem;"
                    >
                      <label
                        for="eventEmail"
                        class="form-label"
                      >
                        Email
                      </label>
                      <input
                        id="eventEmail"
                        v-model="eventEmail"
                        type="text"
                        class="form-control"
                        placeholder="kryptofighter@gmail.com"
                        required
                      >
                      <div
                        v-if="showErrorAlert"
                        class="alert alert-danger mt-3"
                      >
                        Invalid Email format.
                      </div>
                    </div>
                    <div
                      class=""
                      style="margin-bottom: 1rem;"
                    >
                      <label
                        for="eventCode"
                        class="form-label"
                      >
                        Enter Code
                      </label>
                      <div style="display: flex;">
                        <div
                          class="col-7"
                          style="padding: 0; margin: 0;"
                        >
                          <input
                            id="eventCode"
                            v-model="eventCode"
                            type="text"
                            class="form-control"
                            style="background-color: grey;"
                            placeholder=""
                            required
                          >
                        </div>
                        <div
                          class="col-5"
                          style="margin: 0; padding: 0; padding-left: 0.7rem;"
                        >
                          <button
                            :disabled="isOPTDisabled"
                            type="submit"
                            class="btn link-account-btn cooldown"
                            @click="RequestOTP"
                          >
                            <div style="font-size: 14px;">
                              Send Code Via Email
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="isOTPError"
                      style="padding: 5px 10px; width: fit-content; margin-top: -15px; margin-bottom: 10px; color: #c21d1d;"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#c21d1d"
                      ><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                      OTP verification failed.
                    </div>
                    <div
                      class=""
                      style="margin-bottom: 1rem;"
                    >
                      <div
                        class="col-5"
                        style="margin: 0; padding: 0; height: 38px; width: 100%;"
                      >
                        <button
                          :disabled="isLinkDisabled"
                          type="submit"
                          class="btn btn-primary link-account-btn"
                          @click="LinkAccount"
                        >
                          <div style="font-size: 14px;">
                            LINK ACCOUNT
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="row">
                <div>
                  <div class="term-detail">
                    <div>
                      <span> By Continuing, you agree to </span>
                      <a href="">Krypto Fighter's Terms of Service</a>
                      <span> and confirm that you have read </span>
                      <a href="">Krypto Fighter's Privacy Policy</a>
                      .
                    </div>
                    <div>
                      Dont't have an account? 
                      <a
                        href=""
                        class="create-account"
                      >Create now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref } from 'vue';
import { getSignature } from '@/utils/web3Utils';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import {useToast} from 'vue-toast-notification';

export default {
    components: {
    },
    props: {
        showModal: Boolean,
        accessToken: String,
        refCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
            showErrorAlert: false,
            baseUrl: 'https://service.kryptofighters.io/api',
            refTransId: '',
            refOtp: '',
            isLinkDisabled: false,
            isOPTDisabled: false,
            isOTPError: false,
            localAccessToken: ''
        };
    },
    watch: {
      accessToken(newValue) {
        console.log('accessToken'+newValue);
        this.localAccessToken = newValue;
      }
    },
    methods: {
        closeModal() {
            console.log('Closing modal'); // Add this line
            this.$refs.Close.click();
        },
        RandomRefTransID(){
            let refID = '';

            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            for (let i = 0; i < 5; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                refID += characters.charAt(randomIndex);
            }

            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).replace(/\//g, '');

            refID += formattedDate;
            refID += useStore().getWalletAddress.slice(-5);
            
            console.log(refID);

            return refID;
        },
        async RequestOtpApi(email) {
            try {
                const apiUrl = this.baseUrl+'/user/verify-email';

                const requestBody = {
                    walletAddress: useStore().getWalletAddress,
                    email: email,
                    refTransId: this.RandomRefTransID()
                };

                console.log('requestBody:', requestBody);
                console.log('this.localAccessToken', this.localAccessToken);

                const response = await axios.post(apiUrl, requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.localAccessToken}`
                    }
                });

                console.log('Response data:', response);

                this.refTransId = response.data.refTransId;
                this.refOtp = response.data.otpRef;

                this.isOPTDisabled = true;
                this.isLinkDisabled = false;
                setTimeout(() => { this.isOPTDisabled = false; }, 60000);
            } catch (error) {
                console.error('Error:', error);
                alert("Please refresh browser.")
            }
        },
        async VerifyEmailApi(email, OTPCode) {
            try {
                this.isLinkDisabled = true;

                const { signature, challengeMessage, address } = await getSignature();

                const apiUrl = 'https://reward-portal.kryptofighters.io/api/protect/bind-email';

                const requestBody = {
                    signature,
                    address,
                    challengeMessage,
                    email: email,
                    refTransId: this.refTransId,
                    otpCode: OTPCode,
                    refCode: this.refOtp
                };

                //console.log('requestBody:', requestBody);

                console.log('headers:', {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.localAccessToken}`
                    });

                const response = await axios.post(apiUrl, requestBody);

                console.log('Response data:', response.data);
                
                useToast().open({
                    message: 'Link email success!',
                    position: 'top-right',
                    type: 'success',
                });
                
                this.refreshData();
                
            } catch (error) {
                console.error('Error:', error.response.data.message);

                const errorMessage = error.response.data.message.length > 64 ?  error.response.data.message.slice(0, 64) + '...' :  error.response.data.message;
                useToast().open({
                    message: 'Link email error : ' + errorMessage,
                    position: 'top-right',
                    type: 'error',
                });
                this.isOTPError = true;
                this.isLinkDisabled = false;
            }
        },
        RequestOTP(){
            if(document.getElementById('eventEmail').value !== ''){
                console.log('this.localAccessToken', this.localAccessToken);
                this.RequestOtpApi(document.getElementById('eventEmail').value);
            }
        },
        LinkAccount(){
            this.isOTPError = false;
            if(document.getElementById('eventEmail').value !== '' && document.getElementById('eventCode').value !== ''){
                this.VerifyEmailApi(document.getElementById('eventEmail').value, document.getElementById('eventCode').value);
            }
        },
        refreshData() {
          if (this.refCode) {
            console.log('refreshData is null or undefined, not emitting');
            this.recordReferral();
          } 
          console.log('refreshData');
          this.$emit('refreshData');
          // else {
          //   console.log('refreshData');
          //   this.$emit('refreshData');
          // }
          this.closeModal();
        },
        async recordReferral(){
          console.log('recordReferral'+this.refCode);
          try { 
              const wallet = useStore().getWalletAddress;
              const apiUrl = `https://reward-portal.kryptofighters.io/api/referral/record-referral`;
              
              const requestBody = {
                refCode: this.refCode,
                walletAddress: wallet,
                email: document.getElementById('eventEmail').value
              };

              const response = await axios.post(apiUrl, requestBody);
              console.log('response recordReferral ',response);
              useToast().open({
                  message: 'Record referral success! ',
                  position: 'top-right',
                  type: 'success'
              });
              
          } catch (error) {
              // this.error = 'Failed to fetch xp. Please try again later.';
              console.error(error);
              const errorMessage = error.response.data.message.length > 64 ?  error.response.data.message.slice(0, 64) + '...' :  error.response.data.message;
                useToast().open({
                    message: 'Record referral error : ' + errorMessage,
                    position: 'top-right',
                    type: 'error',
                });
          }
        }
    }
};
</script>

<style scoped lang="scss">

.modal-dialog {
    max-width: 650px;
    max-height: 500px;
}

.modal-content {
    border: 0px;
}
.modal-body {
    padding: 0.5rem;
}

.heading-text {
    color: white;
}

.sub-topic-text {
    color: white;
}

.row {
    margin: 0;
    padding: 0;
}

.term-detail {
    padding: 0rem 0.5rem 1rem 0.5rem;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    color: lightgray;
}

a {
    text-decoration: none;
    color: white;
    font-weight: 500;
}

.create-account {
    color: cornflowerblue;
    font-weight: bold;
}

.banner-image {
    height: 400px;
    width: auto;
}

.col-4 {
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.header-row {
    margin-bottom: 0.5rem;
}

.link-account-btn {
    height: 100%; 
    padding: 0; 
    width: 100%;
}

.col-8 {
    height: 100%;
}

button.cooldown {
    background-color: #6aecfe55; 
    border-color: #6aecfe;
    position: relative;
    outline: none;
    overflow: hidden;
    cursor: pointer;

    &:active, &:focus {
        outline: none;
    }

    &:disabled {
        background: darken(#6aecfe55, 10%);
        color: darken(#fff, 15%);
        cursor: default;
        box-shadow: inset 3px 3px 10px 0px rgba(0, 0, 0, 0.2);

        &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 5px;
        background: darken(#6aecfe55, 20%);
        animation: cooldown 60s linear;
        }
    }
}

@keyframes cooldown {
   0% { width: 100%; }
   100% { width: 0; }
}

@media (max-width: 768px) {
    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }
    .term-detail {
        padding: 0.2rem 0.5rem;
        gap: 5px;
    }

    .header-row {
        margin-bottom: 0.2rem;
    }

    .modal-header {
        padding: 0.5rem 1rem;
    }

    .detail-block {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        width: auto;
        height: auto;
        max-height: 328px;
    }
}
</style>
  
