
export const convertToUSD = (price, currency, ethToUsdExchangeRate, kroToUsdExchangeRate) => {
    let usdPrice;
    switch (currency) {
      case 'ETH':
        usdPrice = price * ethToUsdExchangeRate;
        break;
      case 'MATIC':
        usdPrice = price * kroToUsdExchangeRate;
        break;
      case 'JAB':
        usdPrice = 0;
        break;
      case 'KRO':
        usdPrice = price * kroToUsdExchangeRate;
        break;
      default:
        usdPrice = price; // Assume the currency is already in USD
    }
    return usdPrice;
  };

export const findCurrencyByContract = (addr) => {
  var result = {};
  switch (addr.toLowerCase()){
    case process.env.VITE_JAB_CONTRACT_ADDRESS.toLowerCase(): 
      result = {name: 'Jab', currency: 'JAB', contract: addr}; break;
    case process.env.VITE_KRO_CONTRACT_ADDRESS.toLowerCase(): 
      result = {name: 'Kroma', currency: 'KRO',contract: addr}; break;
    case process.env.VITE_ETH_CONTRACT_ADDRESS.toLowerCase(): 
      result = {name: 'Ether', currency: 'ETH', contract: addr}; break;
    default: 
      result = {name: 'Ether', currency: 'ETH', contract: process.env.VITE_ETH_CONTRACT_ADDRESS}; break;
  }
  return result;
};